import axiosInstance from "./axiosInstance";

const gameService = {
  getAll: async () => {
    try {
      const response = await axiosInstance.post("/game/filter");
      return response.data;
    } catch (error) {
      console.error("Error getting game:", error);
    }
  },

  get: async (id) => {
    try {
      const response = await axiosInstance.get(`/game/${id}`);
      return response.data;
    } catch (error) {
      console.error(`Error getting game with id ${id}:`, error);
    }
  },

  create: async (gameData) => {
    try {
      const response = await axiosInstance.post("/game", gameData);
      return response;
    } catch (error) {
      console.error("Error creating game:", error);
    }
  },

  update: async (id, gameData) => {
    try {
      const response = await axiosInstance.put(`/game/${id}`, gameData);
      return response;
    } catch (error) {
      console.error(`Error updating game with id ${id}:`, error);
    }
  },

  updateRange: async (games) => {
    try {
      const response = await axiosInstance.put(`/game/game/range`, games);
      return response;
    } catch (error) {
      console.error(`Error updating games range`, error);
    }
  },

  delete: async (id) => {
    try {
      const response = await axiosInstance.delete(`/game/${id}`);
      return response;
    } catch (error) {
      console.error(`Error deleting game with id ${id}:`, error);
    }
  },
};

export default gameService;
