import React, { Fragment, useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { FileUpload } from "primereact/fileupload";
import { Editor } from "primereact/editor";
import blogService from "../../services/blogService";
import { Toast } from "primereact/toast";
import { Image } from "primereact/image";
import { InputSwitch } from "primereact/inputswitch";

const Blogs = () => {
  const toast = useRef();
  const [visibleBlogDialog, setVisibleBlogDialog] = useState(false);
  const [visibleConfirmDeleteBlogDialog, setVisibleConfirmDeleteBlogDialog] =
    useState(false);
  const [selectedBlog, setSelectedBlog] = useState({
    title: "",
    description: "",
    image: "",
  });
  const [blogs, setBlogs] = useState(null);
  const [blogFormErrors, setBlogFormErrors] = useState({});

  useEffect(() => {
    refresh();
  }, []);

  const refresh = async () => {
    const response = await blogService.getAll();
    if (response) {
      setBlogs(response);
    }
  };

  const validateBlogForm = () => {
    const errors = {};
    if (!selectedBlog.title) {
      errors.title = "Blog Başlığı zorunludur.";
    }
    if (!selectedBlog.description) {
      errors.description = "Blog açıklaması zorunludur.";
    }

    setBlogFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const onRowReorderBlog = async (event) => {
    const reorderedBlogs = event.value;
    const updatedBlogs = reorderedBlogs.map((blog, index) => {
      return { ...blog, position: index + 1 };
    });

    setBlogs(updatedBlogs);

    try {
      await blogService.updateRange(updatedBlogs);
      toast.current.show({
        severity: "success",
        summary: "Başarılı",
        detail: "Bloglar başarıyla yeniden sıralandı",
        life: 3000,
      });
    } catch (error) {
      console.error("Reorder failed", error);
      toast.current.show({
        severity: "error",
        summary: "Hata",
        detail: "Blogları yeniden sıralama başarısız",
        life: 3000,
      });
    }
  };

  const actionTemplateBlog = (rowData) => {
    return (
      <div className="flex ">
        <Button
          icon="pi pi-pencil"
          severity="warning"
          onClick={() => {
            setSelectedBlog(rowData);
            setVisibleBlogDialog(true);
          }}
        />
        <Button
          className="ml-1"
          icon="pi pi-trash"
          severity="danger"
          onClick={() => {
            setSelectedBlog(rowData);
            setVisibleConfirmDeleteBlogDialog(true);
          }}
        />
      </div>
    );
  };

  const confirmDeleteBlog = async () => {
    const response = await blogService.delete(selectedBlog.id);
    if (response) {
      refresh();
      toast.current.show({
        severity: "success",
        summary: "Başarılı",
        detail: "İşlem başarı ile tamamlandı",
        life: 3000,
      });
    }
    setVisibleConfirmDeleteBlogDialog(false);
    setSelectedBlog({
      title: "",
      description: "",
      image: "",
    });
  };

  const customBase64UploaderForBlog = async (event) => {
    const file = event.files[0];
    const reader = new FileReader();
    const blob = await fetch(file.objectURL).then((response) =>
      response.blob()
    );

    reader.readAsDataURL(blob);

    reader.onloadend = async () => {
      const base64data = reader.result;
      if (base64data) {
        setSelectedBlog({
          ...selectedBlog,
          image: base64data,
        });
      }
    };
  };

  const submitBlog = async () => {
    if (!validateBlogForm()) {
      toast.current.show({
        severity: "error",
        summary: "Hata",
        detail: "Lütfen tüm zorunlu alanları doldurun.",
        life: 3000,
      });
      return;
    }

    if (selectedBlog.id) {
      const response = await blogService.update(selectedBlog.id, selectedBlog);

      if (response) {
        refresh();
        setVisibleBlogDialog(false);
        toast.current.show({
          severity: "success",
          summary: "Başarılı",
          detail: "İşlem başarı ile tamamlandı",
          life: 3000,
        });
      }
    } else {
      const response = await blogService.create(selectedBlog);
      if (response) {
        refresh();
        setVisibleBlogDialog(false);
        toast.current.show({
          severity: "success",
          summary: "Başarılı",
          detail: "İşlem başarı ile tamamlandı",
          life: 3000,
        });
      }
    }
  };

  const updateBlogStatus = async (blog, value) => {
    const updatedBlog = { ...blog, status: value === true ? 1 : 0 };
    try {
      await blogService.update(blog.id, updatedBlog);
      refresh();
      toast.current.show({
        severity: "success",
        summary: "Başarılı",
        detail: "Blog durumu başarıyla güncellendi",
        life: 3000,
      });
    } catch (error) {
      console.error("Status update failed", error);
      toast.current.show({
        severity: "error",
        summary: "Hata",
        detail: "Blog durumu güncelleme başarısız",
        life: 3000,
      });
    }
  };

  const truncateHTML = (html, length) => {
    const div = document.createElement("div");
    div.innerHTML = html;
    let truncated = "";
    let remainingLength = length;

    const traverse = (node) => {
      if (node.nodeType === Node.TEXT_NODE) {
        truncated += node.textContent.slice(0, remainingLength);
        remainingLength -= node.textContent.length;
      } else if (node.nodeType === Node.ELEMENT_NODE) {
        truncated += `<${node.nodeName.toLowerCase()}`;
        Array.from(node.attributes).forEach((attr) => {
          truncated += ` ${attr.name}="${attr.value}"`;
        });
        truncated += ">";

        node.childNodes.forEach((child) => {
          if (remainingLength > 0) {
            traverse(child);
          }
        });

        truncated += `</${node.nodeName.toLowerCase()}>`;
      }
    };

    traverse(div);
    return truncated;
  };

  const descriptionTemplate = (rowData) => {
    const truncatedDescription = truncateHTML(rowData.description, 100);
    return <span dangerouslySetInnerHTML={{ __html: truncatedDescription }} />;
  };

  return (
    <Fragment>
      <Toast ref={toast} />
      <div>
        <div className="card ">
          <div className="flex justify-content-between flex-wrap">
            <div className="flex align-items-center justify-content-center">
              <Button
                className="mb-4"
                severity="secondary"
                icon="pi pi-refresh"
                label="Yenile"
                onClick={() => {
                  refresh();
                }}
              />
              <Button
                className="mb-4 ml-1"
                severity="warning"
                icon="pi pi-plus"
                label="Yeni Blog Ekle"
                onClick={() => {
                  setVisibleBlogDialog(true);
                }}
              />
            </div>
          </div>
          <DataTable
            value={blogs}
            reorderableRows
            onRowReorder={(e) => onRowReorderBlog(e)}
            paginator
            rows={5}
            rowsPerPageOptions={[5, 10, 25, 50]}
          >
            <Column rowReorder style={{ width: "3em" }} />
            <Column
              sortable
              field="title"
              header="Blog Adı"
              style={{ width: "10em" }}
            />
            <Column body={descriptionTemplate} header="Blog Açıklaması" />
            <Column
              header="Durum"
              body={(row) => {
                return row ? (
                  <InputSwitch
                    checked={row.status === 1 ? true : false}
                    onChange={(e) => updateBlogStatus(row, e.value)}
                  />
                ) : null;
              }}
            />
            <Column body={actionTemplateBlog} style={{ width: "3em" }} />
          </DataTable>
        </div>
      </div>
      <Dialog
        header={
          selectedBlog && selectedBlog.id ? "Blog Güncelleme" : "Blog Ekleme"
        }
        visible={visibleBlogDialog}
        className="w-full lg:w-8"
        onHide={() => {
          setSelectedBlog({
            title: "",
            description: "",
            image: "",
          });
          setVisibleBlogDialog(false);
        }}
      >
        <div className="p-5 border-round flex-auto">
          <div className="flex gap-5 flex-column-reverse md:flex-row">
            <div className="flex-auto p-fluid">
              <div className="mb-4">
                <label className="block font-medium text-900 mb-3">
                  Blog Görseli
                </label>
                <Image
                  preview
                  width="200"
                  src={
                    selectedBlog && selectedBlog.image
                      ? selectedBlog.image
                      : null
                  }
                />
                <FileUpload
                  mode="basic"
                  url="/api/upload"
                  accept="image/*"
                  customUpload
                  chooseLabel="Resim Seç"
                  onSelect={customBase64UploaderForBlog}
                  className={blogFormErrors.image ? "p-info" : ""}
                />
              </div>
              <div className="mb-4">
                <label className="block font-medium text-900 mb-2">
                  Blog Başlığı
                </label>
                <InputText
                  value={
                    selectedBlog && selectedBlog.title ? selectedBlog.title : ""
                  }
                  onChange={(e) => {
                    setSelectedBlog({
                      ...selectedBlog,
                      title: e.target.value,
                    });
                  }}
                  type="text"
                  className={blogFormErrors.title ? "p-info" : ""}
                />
                {blogFormErrors.title && (
                  <small className="p-info">{blogFormErrors.title}</small>
                )}
              </div>
              <div className="mb-4">
                <label className="block font-medium text-900 mb-2">
                  Blog Açıklaması
                </label>
                <Editor
                  className={blogFormErrors.description ? "p-info" : ""}
                  value={
                    selectedBlog && selectedBlog.description
                      ? selectedBlog.description
                      : ""
                  }
                  onTextChange={(e) =>
                    setSelectedBlog({
                      ...selectedBlog,
                      description: e.htmlValue,
                    })
                  }
                  style={{ height: "320px" }}
                />
                {blogFormErrors.description && (
                  <small className="p-info">{blogFormErrors.description}</small>
                )}
              </div>

              <div>
                <Button
                  label="Kaydet"
                  onClick={() => submitBlog()}
                  className="p-ripple w-auto"
                ></Button>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
      <Dialog
        header="Silme İşlemi Onayı"
        visible={visibleConfirmDeleteBlogDialog}
        className="w-full lg:w-3"
        footer={
          <div>
            <Button
              label="Hayır"
              icon="pi pi-times"
              onClick={() => setVisibleConfirmDeleteBlogDialog(false)}
              className="p-button-text"
            />
            <Button
              label="Evet"
              icon="pi pi-check"
              onClick={confirmDeleteBlog}
              autoFocus
            />
          </div>
        }
        onHide={() => setVisibleConfirmDeleteBlogDialog(false)}
      >
        <p>Bu blogu silmek istediğinizden emin misiniz?</p>
      </Dialog>
    </Fragment>
  );
};

export default Blogs;
