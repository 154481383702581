import React, { Fragment, useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { Dialog } from "primereact/dialog";
import { FileUpload } from "primereact/fileupload";
import { InputTextarea } from "primereact/inputtextarea";
import categoryService from "../../services/categoryService";
import productService from "../../services/productService";
import { Toast } from "primereact/toast";
import { Image } from "primereact/image";
import { Dropdown } from "primereact/dropdown";
import { InputSwitch } from "primereact/inputswitch";

const Products = () => {
  const toast = useRef();
  const [visibleProductDialog, setVisibleProductDialog] = useState(false);
  const [
    visibleConfirmDeleteProductDialog,
    setVisibleConfirmDeleteProductDialog,
  ] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState({
    name: "",
    description: "",
    image: "",
    price: "",
    categoryId: "",
  });
  const [categories, setCategories] = useState(null);
  const [products, setProducts] = useState(null);
  const [productFormErrors, setProductFormErrors] = useState({});

  useEffect(() => {
    refresh();
  }, []);

  const refresh = async () => {
    const fetchProducts = async () => {
      const response = await productService.getAll();
      if (response) {
        setProducts(response);
      }
    };

    const fetchCategories = async () => {
      const response = await categoryService.getAll();
      if (response) {
        setCategories(response);
      }
    };

    fetchCategories();
    fetchProducts();
  };

  const validateProductForm = () => {
    const errors = {};
    if (!selectedProduct.name) {
      errors.name = "Ürün adı zorunludur.";
    }
    if (!selectedProduct.description) {
      errors.description = "Ürün açıklaması zorunludur.";
    }
    if (!selectedProduct.price) {
      errors.price = "Ürün fiyatı zorunludur.";
    }
    if (!selectedProduct.categoryId) {
      errors.categoryId = "Ürün kategorisi zorunludur.";
    }
    setProductFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const onRowReorderProduct = async (event) => {
    const reorderedProducts = event.value;
    const updatedProducts = reorderedProducts.map((product, index) => {
      return { ...product, position: index + 1 };
    });

    setProducts(updatedProducts);

    try {
      await productService.updateRange(updatedProducts);
      toast.current.show({
        severity: "success",
        summary: "Başarılı",
        detail: "Ürünler başarıyla yeniden sıralandı",
        life: 3000,
      });
    } catch (error) {
      console.error("Reorder failed", error);
      toast.current.show({
        severity: "error",
        summary: "Hata",
        detail: "Ürünleri yeniden sıralama başarısız",
        life: 3000,
      });
    }
  };

  const actionTemplateProduct = (rowData) => {
    return (
      <div className="flex">
        <Button
          icon="pi pi-pencil"
          severity="warning"
          onClick={() => {
            setSelectedProduct(rowData);
            setVisibleProductDialog(true);
          }}
        />
        <Button
          className="ml-1"
          icon="pi pi-trash"
          severity="danger"
          onClick={() => {
            setSelectedProduct(rowData);
            setVisibleConfirmDeleteProductDialog(true);
          }}
        />
      </div>
    );
  };

  const confirmDeleteProduct = async () => {
    const response = await productService.delete(selectedProduct.id);
    if (response) {
      refresh();
      toast.current.show({
        severity: "success",
        summary: "Başarılı",
        detail: "İşlem başarı ile tamamlandı",
        life: 3000,
      });
    }
    setVisibleConfirmDeleteProductDialog(false);
    setSelectedProduct({
      name: "",
      description: "",
      image: "",
      price: "",
      categoryId: "",
    });
  };

  const customBase64UploaderForProduct = async (event) => {
    const file = event.files[0];
    const reader = new FileReader();
    const blob = await fetch(file.objectURL).then((response) =>
      response.blob()
    );

    reader.readAsDataURL(blob);

    reader.onloadend = async () => {
      const base64data = reader.result;
      if (base64data) {
        setSelectedProduct({
          ...selectedProduct,
          image: base64data,
        });
      }
    };
  };

  const submitProduct = async () => {
    if (!validateProductForm()) {
      toast.current.show({
        severity: "error",
        summary: "Hata",
        detail: "Lütfen tüm zorunlu alanları doldurun.",
        life: 3000,
      });
      return;
    }

    if (selectedProduct.id) {
      const response = await productService.update(
        selectedProduct.id,
        selectedProduct
      );

      if (response) {
        refresh();
        setVisibleProductDialog(false);
        toast.current.show({
          severity: "success",
          summary: "Başarılı",
          detail: "İşlem başarı ile tamamlandı",
          life: 3000,
        });
      }
    } else {
      const response = await productService.create(selectedProduct);
      if (response) {
        refresh();
        setVisibleProductDialog(false);
        toast.current.show({
          severity: "success",
          summary: "Başarılı",
          detail: "İşlem başarı ile tamamlandı",
          life: 3000,
        });
      }
    }
  };

  const updateProductStatus = async (product, value) => {
    const updatedProduct = { ...product, status: value === true ? 1 : 0 };
    try {
      await productService.update(product.id, updatedProduct);
      refresh();
      toast.current.show({
        severity: "success",
        summary: "Başarılı",
        detail: "Ürün durumu başarıyla güncellendi",
        life: 3000,
      });
    } catch (error) {
      console.error("Status update failed", error);
      toast.current.show({
        severity: "error",
        summary: "Hata",
        detail: "Ürün durumu güncelleme başarısız",
        life: 3000,
      });
    }
  };

  return (
    <Fragment>
      <Toast ref={toast} />
      <div>
        <div className="card">
          <div className="flex justify-content-between flex-wrap">
            <div className="flex align-items-center justify-content-center">
              <Button
                className="mb-4"
                severity="secondary"
                icon="pi pi-refresh"
                label="Yenile"
                onClick={() => {
                  refresh();
                }}
              />
              <Button
                className="mb-4 ml-1"
                severity="info"
                icon="pi pi-plus"
                label="Yeni Ürün Ekle"
                onClick={() => {
                  setVisibleProductDialog(true);
                }}
              />
            </div>
          </div>
          <DataTable
            value={products}
            reorderableRows
            onRowReorder={(e) => onRowReorderProduct(e)}
            paginator
            rows={5}
            rowsPerPageOptions={[5, 10, 25, 50]}
          >
            <Column rowReorder style={{ width: "3em" }} />
            <Column sortable field="name" header="Ürün Adı" />
            <Column field="description" header="Ürün Açıklaması" />
            <Column
              header="Kategori Adı"
              body={(row) => {
                const relatedCategory =
                  categories && categories.find((x) => x.id === row.categoryId);

                return <span>{relatedCategory && relatedCategory.name}</span>;
              }}
            />
            <Column field="price" header="Ürün Fiyatı" />
            <Column
              header="Durum"
              body={(row) => {
                return row ? (
                  <InputSwitch
                    checked={row.status === 1 ? true : false}
                    onChange={(e) => updateProductStatus(row, e.value)}
                  />
                ) : null;
              }}
            />
            <Column body={actionTemplateProduct} style={{ width: "3em" }} />
          </DataTable>
        </div>
      </div>
      <Dialog
        header={
          selectedProduct && selectedProduct.id
            ? "Ürün Güncelleme"
            : "Ürün Ekleme"
        }
        visible={visibleProductDialog}
        className="w-full lg:w-8"
        onHide={() => {
          setSelectedProduct({
            name: "",
            description: "",
            image: "",
            price: "",
            categoryId: "",
          });
          setVisibleProductDialog(false);
        }}
      >
        <div className="p-5 border-round flex-auto">
          <div className="flex gap-5 flex-column-reverse md:flex-row">
            <div className="flex-auto p-fluid">
              <div className="mb-4">
                <label className="block font-medium text-900 mb-3">
                  Ürün Görseli
                </label>
                <Image
                  preview
                  width="200"
                  src={
                    selectedProduct && selectedProduct.image
                      ? selectedProduct.image
                      : null
                  }
                  className={productFormErrors.image ? "p-info" : ""}
                />
                <FileUpload
                  mode="basic"
                  accept="image/*"
                  customUpload
                  chooseLabel="Resim Seç"
                  onSelect={customBase64UploaderForProduct}
                  className={productFormErrors.image ? "p-info" : ""}
                />
                {productFormErrors.image && (
                  <small className="p-info">{productFormErrors.image}</small>
                )}
              </div>
              <div className="mb-4">
                <label className="block font-medium text-900 mb-2">
                  Ürün Kategorisi
                </label>
                <Dropdown
                  value={
                    selectedProduct && selectedProduct.categoryId
                      ? selectedProduct.categoryId
                      : null
                  }
                  onChange={(e) =>
                    setSelectedProduct({
                      ...selectedProduct,
                      categoryId: e.value,
                    })
                  }
                  options={categories}
                  optionLabel="name"
                  optionValue="id"
                  placeholder="Kategori Seç"
                  className={`w-full  ${
                    productFormErrors.categoryId ? "p-info" : ""
                  }`}
                />
                {productFormErrors.categoryId && (
                  <small className="p-info">
                    {productFormErrors.categoryId}
                  </small>
                )}
              </div>

              <div className="mb-4">
                <label className="block font-medium text-900 mb-2">
                  Ürün Adı
                </label>
                <InputText
                  value={
                    selectedProduct && selectedProduct.name
                      ? selectedProduct.name
                      : ""
                  }
                  onChange={(e) => {
                    setSelectedProduct({
                      ...selectedProduct,
                      name: e.target.value,
                    });
                  }}
                  type="text"
                  className={productFormErrors.name ? "p-info" : ""}
                />
                {productFormErrors.name && (
                  <small className="p-info">{productFormErrors.name}</small>
                )}
              </div>

              <div className="mb-4">
                <label className="block font-medium text-900 mb-2">
                  Ürün Açıklaması
                </label>
                <InputTextarea
                  value={
                    selectedProduct && selectedProduct.description
                      ? selectedProduct.description
                      : ""
                  }
                  type="text"
                  rows="5"
                  onChange={(e) => {
                    setSelectedProduct({
                      ...selectedProduct,
                      description: e.target.value,
                    });
                  }}
                  autoResize
                  className={productFormErrors.description ? "p-info" : ""}
                ></InputTextarea>
                {productFormErrors.description && (
                  <small className="p-info">
                    {productFormErrors.description}
                  </small>
                )}
              </div>
              <div className="mb-4">
                <label className="block font-medium text-900 mb-2">
                  Ürün Fiyatı
                </label>
                <InputNumber
                  value={
                    selectedProduct && selectedProduct.price
                      ? selectedProduct.price
                      : null
                  }
                  onValueChange={(e) => {
                    setSelectedProduct({
                      ...selectedProduct,
                      price: e.value,
                    });
                  }}
                  minFractionDigits={2}
                  className={productFormErrors.price ? "p-info" : ""}
                />
                {productFormErrors.price && (
                  <small className="p-info">{productFormErrors.price}</small>
                )}
              </div>

              <div>
                <Button
                  label="Kaydet"
                  onClick={() => submitProduct()}
                  className="p-ripple w-auto"
                ></Button>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
      <Dialog
        header="Silme İşlemi Onayı"
        visible={visibleConfirmDeleteProductDialog}
        className="w-full lg:w-3"
        footer={
          <div>
            <Button
              label="Hayır"
              icon="pi pi-times"
              onClick={() => setVisibleConfirmDeleteProductDialog(false)}
              className="p-button-text"
            />
            <Button
              label="Evet"
              icon="pi pi-check"
              onClick={confirmDeleteProduct}
              autoFocus
            />
          </div>
        }
        onHide={() => {
          setVisibleConfirmDeleteProductDialog(false);
        }}
      >
        <p>Bu ürünü silmek istediğinizden emin misiniz?</p>
      </Dialog>
    </Fragment>
  );
};

export default Products;
