import { jwtDecode } from "jwt-decode";
import { Navigate } from "react-router-dom";

const tokenManager = {
  isTokenExpired(token) {
    const jwtToken = token || window.localStorage.getItem("token");
    if (!jwtToken) return true;
    const decodedToken = jwtDecode(jwtToken);
    return Date.now() >= decodedToken.exp * 1000;
  },

  getExpireMinutes() {
    const jwtToken = window.localStorage.getItem("token");
    if (!jwtToken) return null;
    const decodedToken = jwtDecode(jwtToken);
    return (decodedToken.exp * 1000 - Date.now()) / 1000 / 60;
  },

  addToken(token) {
    if (!this.isTokenExpired(token)) {
      const decodedToken = jwtDecode(token);
      const user = {
        id: decodedToken.sub,
        userName: decodedToken.name,
        email: decodedToken.email,
        firstName: decodedToken.given_name,
        lastName: decodedToken.family_name,
        host: decodedToken.host_identifier,
        dealer: decodedToken.dealer_identifier,
        organization: decodedToken.organization_identifier,
        subDomain: decodedToken.subdomain,
        company: {
          name: decodedToken.company,
          isLegal: decodedToken.companyIsLegal === "True",
          isLocal: decodedToken.companyIsLocal === "True",
        },
        mobilephone: decodedToken.mobilephone,
      };
      window.localStorage.setItem("user", JSON.stringify(user));
      window.localStorage.setItem("token", token);
    }
  },

  clear(reload) {
    window.localStorage.removeItem("user");
    window.localStorage.removeItem("token");
    if (reload) {
      setTimeout(() => {
        window.location.href = "/login";
      }, 1000);
    }
  },

  isTokenValid() {
    const jwtToken = window.localStorage.getItem("token");
    return jwtToken && !this.isTokenExpired(jwtToken);
  },

  getUser() {
    const user = window.localStorage.getItem("user");
    return user ? JSON.parse(user) : null;
  },
};

export default tokenManager;
