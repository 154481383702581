import axiosInstance from "./axiosInstance";

const blogService = {
  getAll: async () => {
    try {
      const response = await axiosInstance.get("/blog");
      return response.data;
    } catch (error) {
      console.error("Error getting blog:", error);
    }
  },

  get: async (id) => {
    try {
      const response = await axiosInstance.get(`/blog/${id}`);
      return response.data;
    } catch (error) {
      console.error(`Error getting blog with id ${id}:`, error);
    }
  },

  create: async (blogData) => {
    try {
      const response = await axiosInstance.post("/blog", blogData);
      return response;
    } catch (error) {
      console.error("Error creating blog:", error);
    }
  },

  update: async (id, blogData) => {
    try {
      const response = await axiosInstance.put(`/blog/${id}`, blogData);
      return response;
    } catch (error) {
      console.error(`Error updating blog with id ${id}:`, error);
    }
  },

  updateRange: async (blogs) => {
    try {
      const response = await axiosInstance.put(`/blog/blog/range`, blogs);
      return response;
    } catch (error) {
      console.error(`Error updating blogs range`, error);
    }
  },

  delete: async (id) => {
    try {
      const response = await axiosInstance.delete(`/blog/${id}`);
      return response;
    } catch (error) {
      console.error(`Error deleting blog with id ${id}:`, error);
    }
  },
};

export default blogService;
