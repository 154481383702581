import React from "react";
import { Navigate } from "react-router-dom";
import tokenManager from "./tokenManager";

const PrivateRouteManager = ({ children }) => {
  return tokenManager.isTokenValid() ? (
    children
  ) : (
    <Navigate to="/login" replace />
  );
};

export default PrivateRouteManager;
