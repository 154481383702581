import axiosInstance from "./axiosInstance";

const productService = {
  getAll: async () => {
    try {
      const response = await axiosInstance.get("/product");
      return response.data;
    } catch (error) {
      console.error("Error getting product:", error);
    }
  },

  get: async (id) => {
    try {
      const response = await axiosInstance.get(`/product/${id}`);
      return response.data;
    } catch (error) {
      console.error(`Error getting product with id ${id}:`, error);
    }
  },

  create: async (productData) => {
    try {
      const response = await axiosInstance.post("/product", productData);
      return response;
    } catch (error) {
      console.error("Error creating product:", error);
    }
  },

  update: async (id, productData) => {
    try {
      const response = await axiosInstance.put(`/product/${id}`, productData);
      return response;
    } catch (error) {
      console.error(`Error updating product with id ${id}:`, error);
    }
  },

  updateRange: async (products) => {
    try {
      const response = await axiosInstance.put(
        `/product/product/range`,
        products
      );
      return response;
    } catch (error) {
      console.error(`Error updating products range`, error);
    }
  },

  delete: async (id) => {
    try {
      const response = await axiosInstance.delete(`/product/${id}`);
      return response;
    } catch (error) {
      console.error(`Error deleting product with id ${id}:`, error);
    }
  },
};

export default productService;
