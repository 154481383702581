import React from "react";

const Footer = () => {
  return (
    <div className="surface-section px-4 py-6 md:px-6 lg:px-8">
      <div className="border-300 pt-5 text-center">
        <img src="images/logo.png" alt="Image" height="65" />

        <div className="text-center">
          <span className="text-sm text-600 cursor-pointer">
            2024 Dario Fo Kafe | Tüm Hakları Saklıdır
          </span>
        </div>
      </div>
    </div>
  );
};

export default Footer;
