import axiosInstance from "./axiosInstance";

const categoryService = {
  getAll: async () => {
    try {
      const response = await axiosInstance.get("/category");
      return response.data;
    } catch (error) {
      console.error("Error getting category:", error);
    }
  },

  get: async (id) => {
    try {
      const response = await axiosInstance.get(`/category/${id}`);
      return response.data;
    } catch (error) {
      console.error(`Error getting category with id ${id}:`, error);
    }
  },

  create: async (categoryData) => {
    try {
      const response = await axiosInstance.post("/category", categoryData);
      return response;
    } catch (error) {
      console.error("Error creating category:", error);
    }
  },

  update: async (id, categoryData) => {
    try {
      const response = await axiosInstance.put(`/category/${id}`, categoryData);
      return response;
    } catch (error) {
      console.error(`Error updating category with id ${id}:`, error);
    }
  },

  updateRange: async (categories) => {
    try {
      const response = await axiosInstance.put(
        `/category/category/range`,
        categories
      );
      return response;
    } catch (error) {
      console.error(`Error updating category range`, error);
    }
  },

  delete: async (id) => {
    try {
      const response = await axiosInstance.delete(`/category/${id}`);
      return response;
    } catch (error) {
      console.error(`Error deleting category with id ${id}:`, error);
    }
  },
};

export default categoryService;
