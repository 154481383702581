import React from "react";

const Main = ({ children }) => {
  return (
    <div className="p-3">
      <div
        className="surface-border p-5 border-round surface-card"
        style={{ minHeight: "30rem" }}
      >
        {children}
      </div>
    </div>
  );
};

export default Main;
