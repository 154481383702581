import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import React, { useState, useRef } from "react";
import authService from "../../services/authService";
import { useNavigate } from "react-router-dom";
import { Toast } from "primereact/toast";

const Login = () => {
  const navigate = useNavigate();
  const toast = useRef(null);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const submit = async () => {
    const response = await authService.login(email, password);

    if (response) {
      toast.current.show({
        severity: "success",
        summary: "Başarılı",
        detail: "Giriş başarılı",
        life: 3000,
      });
      navigate("/");
    } else {
      toast.current.show({
        severity: "error",
        summary: "Hata",
        detail: "Kullanıcı adı veya şifre yanlış",
        life: 3000,
      });
    }
  };

  return (
    <div
      className="p-grid p-justify-center p-align-center"
      style={{ minHeight: "100vh" }}
    >
      <Toast ref={toast} />
      <div className="p-col-12 p-md-3">
        <div className="p-4 lg:shadow-6 shadow-0 border-round bg-white">
          <div className="text-center mb-5">
            <img
              className="fadein animation-duration-3000 animation-iteration-infinite"
              src="/images/dariofo.png"
              width={200}
              alt="Logo"
            ></img>
          </div>
          <div>
            <label className="block text-900 font-medium mb-2">Email</label>
            <InputText
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email address"
              className="w-full mb-3"
            />
            <label className="block text-900 font-medium mb-2">Şifre</label>
            <InputText
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
              className="w-full mb-3"
            />
            <Button
              label="Giriş Yap"
              icon="pi pi-user"
              onClick={submit}
              className="w-full text-gray-50 bg-black"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
