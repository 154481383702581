import React, { useRef } from "react";
import { StyleClass } from "primereact/styleclass";
import { Ripple } from "primereact/ripple";
import { useNavigate } from "react-router-dom";
import tokenManager from "../utils/managers/tokenManager";

const Header = () => {
  const navigate = useNavigate();
  const btnRefMobile = useRef(null);
  const btnRefDropDown = useRef(null);
  return (
    <div
      className="surface-overlay py-3 px-6 shadow-2 flex align-items-center justify-content-between relative lg:static"
      style={{ minHeight: "80px" }}
    >
      <img
        src="images/logo.png"
        onClick={() => navigate("/")}
        alt="bastion-700"
        height={60}
        className="mr-0 lg:mr-6"
      />
      <StyleClass
        nodeRef={btnRefMobile}
        selector="@next"
        enterClassName="hidden"
        leaveToClassName="hidden"
        hideOnOutsideClick
      >
        <div
          ref={btnRefMobile}
          className="cursor-pointer block lg:hidden text-700"
        >
          <i className="pi pi-bars text-4xl"></i>
          <Ripple />
        </div>
      </StyleClass>
      <div className="align-items-center flex-grow-1 justify-content-between hidden lg:flex absolute lg:static w-full surface-overlay left-0 top-100 z-1 shadow-2 lg:shadow-none">
        <ul className="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row">
          <li
            onClick={() => {
              navigate("/");
            }}
          >
            <div className="p-ripple flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150 w-full">
              <span>Anasayfa</span>
              <Ripple />
            </div>
          </li>

          <li
            onClick={() => {
              navigate("/categories");
            }}
          >
            <div className="p-ripple flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150 w-full">
              <span>Kategoriler</span>
              <Ripple />
            </div>
          </li>

          <li
            onClick={() => {
              navigate("/products");
            }}
          >
            <div className="p-ripple flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150 w-full">
              <span>Ürünler</span>
              <Ripple />
            </div>
          </li>
          <li
            onClick={() => {
              navigate("/events");
            }}
          >
            <div className="p-ripple flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150 w-full">
              <span>Etkinlikler</span>
              <Ripple />
            </div>
          </li>
          <li
            onClick={() => {
              navigate("/games");
            }}
          >
            <div className="p-ripple flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150 w-full">
              <span>Oyunlar</span>
              <Ripple />
            </div>
          </li>
          <li
            onClick={() => {
              navigate("/members");
            }}
          >
            <div className="p-ripple flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150 w-full">
              <span>Ekibimiz</span>
              <Ripple />
            </div>
          </li>
          <li
            onClick={() => {
              navigate("/blogs");
            }}
          >
            <div className="p-ripple flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150 w-full">
              <span>Neler Yaptık</span>
              <Ripple />
            </div>
          </li>
          <li
            onClick={() => {
              navigate("/albums");
            }}
          >
            <div className="p-ripple flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150 w-full">
              <span>Albümler</span>
              <Ripple />
            </div>
          </li>
        </ul>
        <ul className="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row border-top-1 surface-border lg:border-top-none">
          <li className="h-full relative">
            <StyleClass
              nodeRef={btnRefDropDown}
              selector="@next"
              enterClassName="hidden"
              enterActiveClassName="scalein"
              leaveToClassName="hidden"
              leaveActiveClassName="fadeout"
              hideOnOutsideClick
            >
              <div
                ref={btnRefDropDown}
                className="p-ripple cursor-pointer h-full inline-flex align-items-center text-600 py-3 lg:py-0 px-3 border-left-2 lg:border-left-none  border-transparent hover:border-500 transition-colors transition-duration-150"
              >
                <span className="mx-2 font-medium text-600">Berkan Kaplan</span>
                <i className="mt-1 pi pi-angle-down"></i>
                <Ripple />
              </div>
            </StyleClass>
            <div className="hidden static lg:absolute w-full surface-overlay left-0 top-100 z-1 shadow-none lg:shadow-2 origin-top border-round pl-3 lg:pl-0">
              <ul className="list-none p-0 m-0">
                <li
                  onClick={() => {
                    navigate("settings");
                  }}
                >
                  <div className="p-ripple cursor-pointer h-full inline-flex align-items-center text-600 border-left-2 border-transparent hover:border-500 transition-colors transition-duration-150 p-3">
                    <span className="pi pi-cog mr-2"></span>
                    <span className="font-medium">Ayarlar</span>
                    <Ripple />
                  </div>
                </li>
                <li
                  onClick={() => {
                    tokenManager.clear(true);
                  }}
                >
                  <div className="p-ripple cursor-pointer h-full inline-flex align-items-center text-600 border-left-2 border-transparent hover:border-500 transition-colors transition-duration-150 p-3">
                    <span className="pi pi-sign-out mr-2"></span>
                    <span className="font-medium">Çıkış Yap</span>
                    <Ripple />
                  </div>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Header;
