const apiBaseUrl = "https://api.dariofo.com.tr/api/";
const rootUrl = "https://panel.dariofo.com.tr";

export const appConfig = {
  apiBaseUrl,
  rootUrl,
  apiRoute: (route) => {
    return `${apiBaseUrl}${route}`;
  },
};

export default appConfig;
