import React, { Fragment, useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { FileUpload } from "primereact/fileupload";
import albumService from "../../services/albumService";
import tMediaService from "../../services/tMediaService";
import { Toast } from "primereact/toast";
import { Image } from "primereact/image";
import { InputSwitch } from "primereact/inputswitch";
import { Divider } from "primereact/divider";

const Albums = () => {
  const toast = useRef();
  const [visibleAlbumDialog, setVisibleAlbumDialog] = useState(false);
  const [visibleImageCollectionDialog, setVisibleImageCollectionDialog] =
    useState(false);
  const [visibleConfirmDeleteAlbumDialog, setVisibleConfirmDeleteAlbumDialog] =
    useState(false);
  const [selectedAlbum, setSelectedAlbum] = useState({
    title: "",
    image: "",
    status: 0,
    position: 0,
  });
  const [albums, setAlbums] = useState(null);
  const [allMedia, setAllMedia] = useState(null);
  const [selectedMedia, setSelectedMedia] = useState(null);
  const [albumFormErrors, setAlbumFormErrors] = useState({});

  useEffect(() => {
    refresh();
  }, []);

  const refresh = async () => {
    const response = await albumService.getAll();
    if (response) {
      setAlbums(response);
    }
  };

  const getAllMedia = async (albumId) => {
    const response = await tMediaService.get(albumId);
    if (response) {
      setAllMedia(response);
    }
  };

  const validateAlbumForm = () => {
    const errors = {};
    if (!selectedAlbum.title) {
      errors.title = "Resim Başlığı zorunludur.";
    }

    if (!selectedAlbum.image) {
      errors.image = "Resim  zorunludur.";
    }

    setAlbumFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const onRowReorderAlbum = async (event) => {
    const reorderedAlbums = event.value;
    const updatedAlbums = reorderedAlbums.map((album, index) => {
      return { ...album, position: index + 1 };
    });

    setAlbums(updatedAlbums);

    try {
      await albumService.updateRange(updatedAlbums);
      toast.current.show({
        severity: "success",
        summary: "Başarılı",
        detail: "Resimler başarıyla yeniden sıralandı",
        life: 3000,
      });
    } catch (error) {
      console.error("Reorder failed", error);
      toast.current.show({
        severity: "error",
        summary: "Hata",
        detail: "Resimleri yeniden sıralama başarısız",
        life: 3000,
      });
    }
  };

  const actionTemplateAlbum = (rowData) => {
    return (
      <div className="flex ">
        <Button
          icon="pi pi-image"
          severity="info"
          onClick={() => {
            getAllMedia(rowData.id);
            setSelectedAlbum(rowData);
            setVisibleImageCollectionDialog(true);
          }}
        />
        <Button
          className="ml-1"
          icon="pi pi-pencil"
          severity="warning"
          onClick={() => {
            setSelectedAlbum(rowData);
            setVisibleAlbumDialog(true);
          }}
        />
        <Button
          className="ml-1"
          icon="pi pi-trash"
          severity="danger"
          onClick={() => {
            setSelectedAlbum(rowData);
            setVisibleConfirmDeleteAlbumDialog(true);
          }}
        />
      </div>
    );
  };

  const confirmDeleteAlbum = async () => {
    const response = await albumService.delete(selectedAlbum.id);
    if (response) {
      refresh();
      toast.current.show({
        severity: "success",
        summary: "Başarılı",
        detail: "İşlem başarı ile tamamlandı",
        life: 3000,
      });
    }
    setVisibleConfirmDeleteAlbumDialog(false);
    setSelectedAlbum({
      title: "",
      image: "",
      status: 0,
      position: 0,
    });
  };

  const customBase64UploaderForAlbum = async (event) => {
    const file = event.files[0];
    const reader = new FileReader();
    const blob = await fetch(file.objectURL).then((response) =>
      response.blob()
    );

    reader.readAsDataURL(blob);

    reader.onloadend = async () => {
      const base64data = reader.result;
      if (base64data) {
        setSelectedAlbum({
          ...selectedAlbum,
          image: base64data,
        });
      }
    };
  };

  const customBase64UploaderForAlbumMedia = async (event) => {
    const file = event.files[0];
    const reader = new FileReader();
    const blob = await fetch(file.objectURL).then((response) =>
      response.blob()
    );

    reader.readAsDataURL(blob);

    reader.onloadend = async () => {
      const base64data = reader.result;
      if (base64data) {
        setSelectedMedia({
          parentId: selectedAlbum.id,
          data: base64data,
        });
      }
    };
  };

  const submitAlbum = async () => {
    if (!validateAlbumForm()) {
      toast.current.show({
        severity: "error",
        summary: "Hata",
        detail: "Lütfen tüm zorunlu alanları doldurun.",
        life: 3000,
      });
      return;
    }

    if (selectedAlbum.id) {
      const response = await albumService.update(
        selectedAlbum.id,
        selectedAlbum
      );

      if (response) {
        refresh();
        setVisibleAlbumDialog(false);
        toast.current.show({
          severity: "success",
          summary: "Başarılı",
          detail: "İşlem başarı ile tamamlandı",
          life: 3000,
        });
      }
    } else {
      const response = await albumService.create(selectedAlbum);
      if (response) {
        refresh();
        setVisibleAlbumDialog(false);
        toast.current.show({
          severity: "success",
          summary: "Başarılı",
          detail: "İşlem başarı ile tamamlandı",
          life: 3000,
        });
      }
    }
  };

  const updateAlbumStatus = async (album, value) => {
    const updatedAlbum = { ...album, status: value === true ? 1 : 0 };
    try {
      await albumService.update(album.id, updatedAlbum);
      refresh();
      toast.current.show({
        severity: "success",
        summary: "Başarılı",
        detail: "Resim durumu başarıyla güncellendi",
        life: 3000,
      });
    } catch (error) {
      console.error("Status update failed", error);
      toast.current.show({
        severity: "error",
        summary: "Hata",
        detail: "Resim durumu güncelleme başarısız",
        life: 3000,
      });
    }
  };

  return (
    <Fragment>
      <Toast ref={toast} />
      <div>
        <div className="card ">
          <div className="flex justify-content-between flex-wrap">
            <div className="flex align-items-center justify-content-center">
              <Button
                className="mb-4"
                severity="secondary"
                icon="pi pi-refresh"
                label="Yenile"
                onClick={() => {
                  refresh();
                }}
              />
              <Button
                className="mb-4 ml-1"
                severity="warning"
                icon="pi pi-plus"
                label="Yeni Albüm Ekle"
                onClick={() => {
                  setVisibleAlbumDialog(true);
                }}
              />
            </div>
          </div>
          <DataTable
            value={albums}
            reorderableRows
            onRowReorder={(e) => onRowReorderAlbum(e)}
            paginator
            rows={5}
            rowsPerPageOptions={[5, 10, 25, 50]}
          >
            <Column rowReorder style={{ width: "3em" }} />
            <Column sortable field="title" header="Albüm Yazısı" />
            <Column
              header="Durum"
              body={(row) => {
                return row ? (
                  <InputSwitch
                    checked={row.status === 1 ? true : false}
                    onChange={(e) => updateAlbumStatus(row, e.value)}
                  />
                ) : null;
              }}
            />
            <Column body={actionTemplateAlbum} style={{ width: "3em" }} />
          </DataTable>
        </div>
      </div>
      <Dialog
        header={
          selectedAlbum && selectedAlbum.id
            ? "Albüm Güncelleme"
            : "Albüm Ekleme"
        }
        visible={visibleAlbumDialog}
        className="w-full lg:w-8"
        onHide={() => {
          setSelectedAlbum({
            name: "",
            image: "",
            status: 0,
            position: 0,
          });
          setVisibleAlbumDialog(false);
        }}
      >
        <div className="p-5 border-round flex-auto">
          <div className="flex gap-5 flex-column-reverse md:flex-row">
            <div className="flex-auto p-fluid">
              <div className="mb-4">
                <label className="block font-medium text-900 mb-3">Resim</label>
                <Image
                  preview
                  width="200"
                  src={
                    selectedAlbum && selectedAlbum.image
                      ? selectedAlbum.image
                      : null
                  }
                  className={albumFormErrors.image ? "p-info mb-2" : "mb-2"}
                />
                <FileUpload
                  mode="basic"
                  url="/api/upload"
                  accept="image/*"
                  customUpload
                  chooseLabel="Kapak Resmi Seç"
                  onSelect={customBase64UploaderForAlbum}
                  className={albumFormErrors.image ? "p-info" : ""}
                />
                {albumFormErrors.image && (
                  <small className="p-info">{albumFormErrors.image}</small>
                )}
              </div>
              <div className="mb-4">
                <label className="block font-medium text-900 mb-2">
                  Albüm Yazısı
                </label>
                <InputText
                  value={
                    selectedAlbum && selectedAlbum.title
                      ? selectedAlbum.title
                      : ""
                  }
                  onChange={(e) => {
                    setSelectedAlbum({
                      ...selectedAlbum,
                      title: e.target.value,
                    });
                  }}
                  type="text"
                  className={albumFormErrors.title ? "p-info" : ""}
                />
                {albumFormErrors.title && (
                  <small className="p-info">{albumFormErrors.title}</small>
                )}
              </div>

              <div>
                <Button
                  label="Kaydet"
                  onClick={() => submitAlbum()}
                  className="p-ripple w-auto"
                ></Button>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
      <Dialog
        header={"Albüm Resimleri"}
        visible={visibleImageCollectionDialog}
        className="w-full lg:w-6"
        onHide={() => {
          setSelectedAlbum(null);
          setVisibleImageCollectionDialog(false);
        }}
      >
        <div className="p-4">
          <div className="flex align-items-center mb-4 gap-3">
            <FileUpload
              mode="basic"
              url="/api/upload"
              accept="image/*"
              customUpload
              chooseLabel="Resim Seç"
              onSelect={customBase64UploaderForAlbumMedia}
              className="w-full"
            />
            <Button
              label="Ekle"
              onClick={async () => {
                const response = await tMediaService.create(selectedMedia);

                if (response) {
                  toast.current.show({
                    severity: "success",
                    summary: "Başarılı",
                    detail: "Resim başarıyla eklendi",
                    life: 3000,
                  });
                  setSelectedAlbum(null);
                  setSelectedMedia(null);
                  setVisibleImageCollectionDialog(false);
                }
              }}
            />
          </div>
          <Divider />
          <div className="grid">
            {allMedia && allMedia.length > 0
              ? allMedia.map((media, index) => (
                  <div
                    key={index}
                    className="col-12 sm:col-6 md:col-4 lg:col-3"
                  >
                    <div className="flex flex-column align-items-center">
                      <Image
                        src={media.data}
                        preview
                        height="150"
                        imageClassName="border-round-lg"
                      />
                      <Button
                        icon="pi pi-trash"
                        className="p-button-danger mt-2"
                        onClick={async () => {
                          const response = await tMediaService.delete(media.id);
                          if (response) {
                            toast.current.show({
                              severity: "success",
                              summary: "Başarılı",
                              detail: "Resim başarıyla silindi",
                              life: 3000,
                            });
                            setSelectedAlbum(null);
                            setSelectedMedia(null);
                            setVisibleImageCollectionDialog(false);
                          }
                        }}
                      />
                    </div>
                  </div>
                ))
              : null}
          </div>
        </div>
      </Dialog>
      <Dialog
        header="Silme İşlemi Onayı"
        visible={visibleConfirmDeleteAlbumDialog}
        className="w-full lg:w-3"
        footer={
          <div>
            <Button
              label="Hayır"
              icon="pi pi-times"
              onClick={() => setVisibleConfirmDeleteAlbumDialog(false)}
              className="p-button-text"
            />
            <Button
              label="Evet"
              icon="pi pi-check"
              onClick={confirmDeleteAlbum}
              autoFocus
            />
          </div>
        }
        onHide={() => setVisibleConfirmDeleteAlbumDialog(false)}
      >
        <p>Bu albumu silmek istediğinizden emin misiniz?</p>
      </Dialog>
    </Fragment>
  );
};

export default Albums;
