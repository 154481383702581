import axiosInstance from "./axiosInstance";

const eventService = {
  getAll: async () => {
    try {
      const response = await axiosInstance.post("/event/filter");
      return response.data;
    } catch (error) {
      console.error("Error getting event:", error);
    }
  },

  get: async (id) => {
    try {
      const response = await axiosInstance.get(`/event/${id}`);
      return response.data;
    } catch (error) {
      console.error(`Error getting event with id ${id}:`, error);
    }
  },

  create: async (eventData) => {
    try {
      const response = await axiosInstance.post("/event", eventData);
      return response;
    } catch (error) {
      console.error("Error creating event:", error);
    }
  },

  update: async (id, eventData) => {
    try {
      const response = await axiosInstance.put(`/event/${id}`, eventData);
      return response;
    } catch (error) {
      console.error(`Error updating event with id ${id}:`, error);
    }
  },

  updateRange: async (events) => {
    try {
      const response = await axiosInstance.put(`/event/event/range`, events);
      return response;
    } catch (error) {
      console.error(`Error updating events range`, error);
    }
  },

  delete: async (id) => {
    try {
      const response = await axiosInstance.delete(`/event/${id}`);
      return response;
    } catch (error) {
      console.error(`Error deleting event with id ${id}:`, error);
    }
  },
};

export default eventService;
