import axiosInstance from "./axiosInstance";
import tokenManager from "../utils/managers/tokenManager";

const authService = {
  login: async (email, password) => {
    try {
      const response = await axiosInstance.post("/auth/login", {
        email: email,
        password: password,
      });

      tokenManager.addToken(response.data.token);

      return response.data;
    } catch (error) {
      console.error("Error login:", error);
      return null;
    }
  },
};

export default authService;
