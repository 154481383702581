import axiosInstance from "./axiosInstance";

const constantService = {
  getAboutUsData: async () => {
    try {
      const response = await axiosInstance.get(
        `/constant/5AD892B4-D276-4B8F-80BD-90111DFF9EEF`
      );
      return response.data;
    } catch (error) {
      console.error(`Error getting about us data`, error);
    }
  },
  getSceneData: async () => {
    try {
      const response = await axiosInstance.get(
        "/constant/A3F0F125-3744-47BD-A072-D55820F32CC4"
      );
      return response.data;
    } catch (error) {
      console.error(`Error getting scene data:`, error);
    }
  },

  updateAboutUs: async (aboutUsData) => {
    try {
      const response = await axiosInstance.put(
        "/constant/5AD892B4-D276-4B8F-80BD-90111DFF9EEF",
        aboutUsData
      );
      return response;
    } catch (error) {
      console.error(`Error updating about us:`, error);
    }
  },

  updateScene: async (sceneData) => {
    try {
      const response = await axiosInstance.put(
        "/constant/A3F0F125-3744-47BD-A072-D55820F32CC4",
        sceneData
      );
      return response;
    } catch (error) {
      console.error(`Error updating scene`, error);
    }
  },
};

export default constantService;
