import axiosInstance from "./axiosInstance";

const memberService = {
  getAll: async () => {
    try {
      const response = await axiosInstance.get("/member");
      return response.data;
    } catch (error) {
      console.error("Error getting member:", error);
    }
  },

  get: async (id) => {
    try {
      const response = await axiosInstance.get(`/member/${id}`);
      return response.data;
    } catch (error) {
      console.error(`Error getting member with id ${id}:`, error);
    }
  },

  create: async (memberData) => {
    try {
      const response = await axiosInstance.post("/member", memberData);
      return response;
    } catch (error) {
      console.error("Error creating member:", error);
    }
  },

  update: async (id, memberData) => {
    try {
      const response = await axiosInstance.put(`/member/${id}`, memberData);
      return response;
    } catch (error) {
      console.error(`Error updating member with id ${id}:`, error);
    }
  },

  updateRange: async (members) => {
    try {
      const response = await axiosInstance.put(`/member/member/range`, members);
      return response;
    } catch (error) {
      console.error(`Error updating member range`, error);
    }
  },

  delete: async (id) => {
    try {
      const response = await axiosInstance.delete(`/member/${id}`);
      return response;
    } catch (error) {
      console.error(`Error deleting member with id ${id}:`, error);
    }
  },
};

export default memberService;
