import React, { Fragment, useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { Dialog } from "primereact/dialog";
import { FileUpload } from "primereact/fileupload";
import { Editor } from "primereact/editor";
import gameService from "../../services/gameService";
import tMediaService from "../../services/tMediaService";
import { Toast } from "primereact/toast";
import { Image } from "primereact/image";
import { InputSwitch } from "primereact/inputswitch";
import { Calendar } from "primereact/calendar";
import { addLocale } from "primereact/api";
import { Dropdown } from "primereact/dropdown";
import { Divider } from "primereact/divider";

const Games = () => {
  const toast = useRef();
  const [visibleGameDialog, setVisibleGameDialog] = useState(false);
  const [visibleImageCollectionDialog, setVisibleImageCollectionDialog] =
    useState(false);
  const [visibleConfirmDeleteGameDialog, setVisibleConfirmDeleteGameDialog] =
    useState(false);
  const [selectedGame, setSelectedGame] = useState({
    name: "",
    description: "",
    image: "",
    price: "",
    categoryId: "",
  });
  const [games, setGames] = useState(null);
  const [allMedia, setAllMedia] = useState(null);
  const [selectedMedia, setSelectedMedia] = useState(null);
  const [gameFormErrors, setGameFormErrors] = useState({});

  addLocale("tr", {
    firstDayOfWeek: 0,
    showMonthAfterYear: true,
    dayNames: [
      "pazartesi",
      "salı",
      "çarşamba",
      "perşembe",
      "cuma",
      "cumartesi",
      "pazar",
    ],
    dayNamesShort: ["pzt", "sal", "çar", "per", "cum", "cmt", "paz"],
    dayNamesMin: ["PTS", "SAL", "ÇAR", "PER", "CUM", "CMT", "PAZ"],
    monthNames: [
      "Ocak",
      "Şubat",
      "Mart",
      "Nisan",
      "Mayıs",
      "Haziran",
      "Temmuz",
      "Ağustos",
      "Eylül",
      "Ekim",
      "Kasım",
      "Aralık",
    ],
    monthNamesShort: [
      "oca",
      "şub",
      "mar",
      "nis",
      "may",
      "haz",
      "tem",
      "ağu",
      "eyl",
      "ek",
      "kas",
      "ara",
    ],
    today: "Bugün",
    clear: "Temizle",
  });

  useEffect(() => {
    refresh();
  }, []);

  const refresh = async () => {
    const fetchGames = async () => {
      const response = await gameService.getAll();
      if (response) {
        setGames(response);
      }
    };

    fetchGames();
  };

  const getAllMedia = async (albumId) => {
    const response = await tMediaService.get(albumId);
    if (response) {
      setAllMedia(response);
    }
  };

  const countries = [
    { id: "Ankara", name: "Ankara" },
    { id: "İstanbul", name: "İstanbul" },
    { id: "Eskişehir", name: "Eskişehir" },
    { id: "Diğer", name: "Diğer" },
  ];

  const validateGameForm = () => {
    const errors = {};
    if (!selectedGame.name) {
      errors.name = "Oyun adı zorunludur.";
    }
    if (!selectedGame.description) {
      errors.description = "Oyun açıklaması zorunludur.";
    }

    if (!selectedGame.country) {
      errors.description = "Oyun şehiri zorunludur.";
    }
    if (!selectedGame.price) {
      errors.price = "Oyun fiyatı zorunludur.";
    }
    setGameFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const onRowReorderGame = async (game) => {
    const reorderedGames = game.value;
    const updatedGames = reorderedGames.map((game, index) => {
      return { ...game, position: index + 1 };
    });

    setGames(updatedGames);

    try {
      await gameService.updateRange(updatedGames);
      toast.current.show({
        severity: "success",
        summary: "Başarılı",
        detail: "Oyunler başarıyla yeniden sıralandı",
        life: 3000,
      });
    } catch (error) {
      console.error("Reorder failed", error);
      toast.current.show({
        severity: "error",
        summary: "Hata",
        detail: "Oyunleri yeniden sıralama başarısız",
        life: 3000,
      });
    }
  };

  const actionTemplateGame = (rowData) => {
    return (
      <div className="flex">
        <Button
          icon="pi pi-image"
          severity="info"
          onClick={() => {
            getAllMedia(rowData.id);
            setSelectedGame(rowData);
            setVisibleImageCollectionDialog(true);
          }}
        />
        <Button
          className="ml-1"
          icon="pi pi-pencil"
          severity="warning"
          onClick={() => {
            setSelectedGame(rowData);
            setVisibleGameDialog(true);
          }}
        />
        <Button
          className="ml-1"
          icon="pi pi-trash"
          severity="danger"
          onClick={() => {
            setSelectedGame(rowData);
            setVisibleConfirmDeleteGameDialog(true);
          }}
        />
      </div>
    );
  };

  const confirmDeleteGame = async () => {
    const response = await gameService.delete(selectedGame.id);
    if (response) {
      refresh();
      toast.current.show({
        severity: "success",
        summary: "Başarılı",
        detail: "İşlem başarı ile tamamlandı",
        life: 3000,
      });
    }
    setVisibleConfirmDeleteGameDialog(false);
    setSelectedGame({
      name: "",
      description: "",
      image: "",
      price: "",
      categoryId: "",
    });
  };

  const customBase64UploaderForGame = async (game) => {
    const file = game.files[0];
    const reader = new FileReader();
    const blob = await fetch(file.objectURL).then((response) =>
      response.blob()
    );

    reader.readAsDataURL(blob);

    reader.onloadend = async () => {
      const base64data = reader.result;
      if (base64data) {
        setSelectedGame({
          ...selectedGame,
          image: base64data,
        });
      }
    };
  };

  const customBase64UploaderForGameMedia = async (event) => {
    const file = event.files[0];
    const reader = new FileReader();
    const blob = await fetch(file.objectURL).then((response) =>
      response.blob()
    );

    reader.readAsDataURL(blob);

    reader.onloadend = async () => {
      const base64data = reader.result;
      if (base64data) {
        setSelectedMedia({
          parentId: selectedGame.id,
          data: base64data,
        });
      }
    };
  };

  const submitGame = async () => {
    if (!validateGameForm()) {
      toast.current.show({
        severity: "error",
        summary: "Hata",
        detail: "Lütfen tüm zorunlu alanları doldurun.",
        life: 3000,
      });
      return;
    }

    if (selectedGame.id) {
      const response = await gameService.update(selectedGame.id, selectedGame);

      if (response) {
        refresh();
        setVisibleGameDialog(false);
        toast.current.show({
          severity: "success",
          summary: "Başarılı",
          detail: "İşlem başarı ile tamamlandı",
          life: 3000,
        });
      }
    } else {
      const response = await gameService.create(selectedGame);
      if (response) {
        refresh();
        setVisibleGameDialog(false);
        toast.current.show({
          severity: "success",
          summary: "Başarılı",
          detail: "İşlem başarı ile tamamlandı",
          life: 3000,
        });
      }
    }
  };

  const updateGameStatus = async (game, value) => {
    const updatedGame = { ...game, status: value === true ? 1 : 0 };
    try {
      await gameService.update(game.id, updatedGame);
      refresh();
      toast.current.show({
        severity: "success",
        summary: "Başarılı",
        detail: "Oyun durumu başarıyla güncellendi",
        life: 3000,
      });
    } catch (error) {
      console.error("Status update failed", error);
      toast.current.show({
        severity: "error",
        summary: "Hata",
        detail: "Oyun durumu güncelleme başarısız",
        life: 3000,
      });
    }
  };

  return (
    <Fragment>
      <Toast ref={toast} />
      <div>
        <div className="card">
          <div className="flex justify-content-between flex-wrap">
            <div className="flex align-items-center justify-content-center">
              <Button
                className="mb-4"
                severity="secondary"
                icon="pi pi-refresh"
                label="Yenile"
                onClick={() => {
                  refresh();
                }}
              />
              <Button
                className="mb-4 ml-1"
                severity="help"
                icon="pi pi-plus"
                label="Yeni Oyun Ekle"
                onClick={() => {
                  setVisibleGameDialog(true);
                }}
              />
            </div>
          </div>
          <DataTable
            value={games}
            reorderableRows
            onRowReorder={(e) => onRowReorderGame(e)}
            paginator
            rows={5}
            rowsPerPageOptions={[5, 10, 25, 50]}
          >
            <Column rowReorder style={{ width: "3em" }} />
            <Column sortable field="name" header="Oyun Adı" />
            <Column field="price" header="Oyun Fiyatı" />
            <Column
              header="Durum"
              body={(row) => {
                return row ? (
                  <InputSwitch
                    checked={row.status === 1 ? true : false}
                    onChange={(e) => updateGameStatus(row, e.value)}
                  />
                ) : null;
              }}
            />
            <Column body={actionTemplateGame} style={{ width: "3em" }} />
          </DataTable>
        </div>
      </div>
      <Dialog
        header={
          selectedGame && selectedGame.id ? "Oyun Güncelleme" : "Oyun Ekleme"
        }
        visible={visibleGameDialog}
        className="w-full lg:w-8"
        onHide={() => {
          setSelectedGame({
            name: "",
            description: "",
            image: "",
            price: "",
            categoryId: "",
          });
          setVisibleGameDialog(false);
        }}
      >
        <div className="p-5 border-round flex-auto">
          <div className="flex gap-5 flex-column-reverse md:flex-row">
            <div className="flex-auto p-fluid">
              <div className="mb-4">
                <label className="block font-medium text-900 mb-3">
                  Oyun Görseli
                </label>
                <Image
                  preview
                  width="200"
                  src={
                    selectedGame && selectedGame.image
                      ? selectedGame.image
                      : null
                  }
                />
                <FileUpload
                  mode="basic"
                  accept="image/*"
                  customUpload
                  chooseLabel="Resim Seç"
                  onSelect={customBase64UploaderForGame}
                  className={gameFormErrors.image ? "p-info" : ""}
                />
              </div>

              <div className="mb-4">
                <label className="block font-medium text-900 mb-2">
                  Oyun Adı
                </label>
                <InputText
                  value={
                    selectedGame && selectedGame.name ? selectedGame.name : ""
                  }
                  onChange={(e) => {
                    setSelectedGame({
                      ...selectedGame,
                      name: e.target.value,
                    });
                  }}
                  type="text"
                  className={gameFormErrors.name ? "p-info" : ""}
                />
                {gameFormErrors.name && (
                  <small className="p-info">{gameFormErrors.name}</small>
                )}
              </div>

              <div className="mb-4">
                <label className="block font-medium text-900 mb-2">
                  Oyun Lokasyonu
                </label>
                <InputText
                  value={
                    selectedGame && selectedGame.location
                      ? selectedGame.location
                      : ""
                  }
                  onChange={(e) => {
                    setSelectedGame({
                      ...selectedGame,
                      location: e.target.value,
                    });
                  }}
                  type="text"
                  className={gameFormErrors.location ? "p-info" : ""}
                />
                {gameFormErrors.location && (
                  <small className="p-info">{gameFormErrors.location}</small>
                )}
              </div>

              <div className="mb-4">
                <label className="block font-medium text-900 mb-2">
                  Oyun Şehiri
                </label>
                <Dropdown
                  value={
                    selectedGame && selectedGame.country
                      ? selectedGame.country
                      : null
                  }
                  onChange={(e) =>
                    setSelectedGame({
                      ...selectedGame,
                      country: e.value,
                    })
                  }
                  options={countries}
                  optionLabel="name"
                  optionValue="id"
                  placeholder="Kategori Seç"
                  className={`w-full  ${
                    gameFormErrors.country ? "p-info" : ""
                  }`}
                />
                {gameFormErrors.country && (
                  <small className="p-info">{gameFormErrors.country}</small>
                )}
              </div>

              <div className="mb-4">
                <label className="block font-medium text-900 mb-2">
                  Oyun Açıklaması
                </label>
                <Editor
                  className={gameFormErrors.description ? "p-info" : ""}
                  value={
                    selectedGame && selectedGame.description
                      ? selectedGame.description
                      : ""
                  }
                  onTextChange={(e) =>
                    setSelectedGame({
                      ...selectedGame,
                      description: e.htmlValue,
                    })
                  }
                  style={{ height: "320px" }}
                />
                {gameFormErrors.description && (
                  <small className="p-info">{gameFormErrors.description}</small>
                )}
              </div>

              <div className="mb-4">
                <label className="block font-medium text-900 mb-2">
                  Oyun Bağlantı Adresi
                </label>
                <InputText
                  value={
                    selectedGame && selectedGame.link ? selectedGame.link : ""
                  }
                  onChange={(e) => {
                    setSelectedGame({
                      ...selectedGame,
                      link: e.target.value,
                    });
                  }}
                  type="text"
                />
                {gameFormErrors.name && (
                  <small className="p-info">{gameFormErrors.name}</small>
                )}
              </div>

              <div className="mb-4">
                <label className="block font-medium text-900 mb-2">
                  Oyun Fiyatı
                </label>
                <InputNumber
                  value={
                    selectedGame && selectedGame.price
                      ? selectedGame.price
                      : null
                  }
                  onValueChange={(e) => {
                    setSelectedGame({
                      ...selectedGame,
                      price: e.value,
                    });
                  }}
                  minFractionDigits={2}
                  className={gameFormErrors.price ? "p-info" : ""}
                />
                {gameFormErrors.price && (
                  <small className="p-info">{gameFormErrors.price}</small>
                )}
              </div>

              <div className="mb-4">
                <label className="block font-medium text-900 mb-2">
                  Oyun Tarihi
                </label>
                <Calendar
                  dateFormat="dd/mm/yy"
                  value={
                    selectedGame && selectedGame.date
                      ? new Date(selectedGame.date)
                      : ""
                  }
                  onChange={(e) => {
                    setSelectedGame({
                      ...selectedGame,
                      date: e.value,
                    });
                  }}
                  locale="tr"
                  showTime
                  hourFormat="24"
                />
                {gameFormErrors.location && (
                  <small className="p-info">{gameFormErrors.date}</small>
                )}
              </div>

              <div>
                <Button
                  label="Kaydet"
                  onClick={() => submitGame()}
                  className="p-ripple w-auto"
                ></Button>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
      <Dialog
        header={"Oyun Resimleri"}
        visible={visibleImageCollectionDialog}
        className="w-full lg:w-6"
        onHide={() => {
          setSelectedGame(null);
          setVisibleImageCollectionDialog(false);
        }}
      >
        <div className="p-4">
          <div className="flex align-items-center mb-4 gap-3">
            <FileUpload
              mode="basic"
              url="/api/upload"
              accept="image/*"
              customUpload
              chooseLabel="Resim Seç"
              onSelect={customBase64UploaderForGameMedia}
              className="w-full"
            />
            <Button
              label="Ekle"
              onClick={async () => {
                const response = await tMediaService.create(selectedMedia);

                if (response) {
                  toast.current.show({
                    severity: "success",
                    summary: "Başarılı",
                    detail: "Resim başarıyla eklendi",
                    life: 3000,
                  });
                  setSelectedGame(null);
                  setSelectedMedia(null);
                  setVisibleImageCollectionDialog(false);
                }
              }}
            />
          </div>
          <Divider />
          <div className="grid">
            {allMedia && allMedia.length > 0
              ? allMedia.map((media, index) => (
                  <div
                    key={index}
                    className="col-12 sm:col-6 md:col-4 lg:col-3"
                  >
                    <div className="flex flex-column align-items-center">
                      <Image
                        src={media.data}
                        preview
                        height="150"
                        imageClassName="border-round-lg"
                      />
                      <Button
                        icon="pi pi-trash"
                        className="p-button-danger mt-2"
                        onClick={async () => {
                          const response = await tMediaService.delete(media.id);
                          if (response) {
                            toast.current.show({
                              severity: "success",
                              summary: "Başarılı",
                              detail: "Resim başarıyla silindi",
                              life: 3000,
                            });
                            setSelectedGame(null);
                            setSelectedMedia(null);
                            setVisibleImageCollectionDialog(false);
                          }
                        }}
                      />
                    </div>
                  </div>
                ))
              : null}
          </div>
        </div>
      </Dialog>
      <Dialog
        header="Silme İşlemi Onayı"
        visible={visibleConfirmDeleteGameDialog}
        className="w-full lg:w-3"
        footer={
          <div>
            <Button
              label="Hayır"
              icon="pi pi-times"
              onClick={() => setVisibleConfirmDeleteGameDialog(false)}
              className="p-button-text"
            />
            <Button
              label="Evet"
              icon="pi pi-check"
              onClick={confirmDeleteGame}
              autoFocus
            />
          </div>
        }
        onHide={() => {
          setVisibleConfirmDeleteGameDialog(false);
        }}
      >
        <p>Bu etkinliği silmek istediğinizden emin misiniz?</p>
      </Dialog>
    </Fragment>
  );
};

export default Games;
