import React, { Fragment, useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { FileUpload } from "primereact/fileupload";
import { InputTextarea } from "primereact/inputtextarea";
import categoryService from "../../services/categoryService";
import { Toast } from "primereact/toast";
import { Image } from "primereact/image";
import { InputSwitch } from "primereact/inputswitch";

const Categories = () => {
  const toast = useRef();
  const [visibleCategoryDialog, setVisibleCategoryDialog] = useState(false);
  const [
    visibleConfirmDeleteCategoryDialog,
    setVisibleConfirmDeleteCategoryDialog,
  ] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState({
    name: "",
    description: "",
    image: "",
  });
  const [categories, setCategories] = useState(null);
  const [categoryFormErrors, setCategoryFormErrors] = useState({});

  useEffect(() => {
    refresh();
  }, []);

  const refresh = async () => {
    const response = await categoryService.getAll();
    if (response) {
      setCategories(response);
    }
  };

  const validateCategoryForm = () => {
    const errors = {};
    if (!selectedCategory.name) {
      errors.name = "Kategori adı zorunludur.";
    }
    if (!selectedCategory.description) {
      errors.description = "Kategori açıklaması zorunludur.";
    }
    setCategoryFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const onRowReorderCategory = async (event) => {
    const reorderedCategories = event.value;
    const updatedCategories = reorderedCategories.map((category, index) => {
      return { ...category, position: index + 1 };
    });

    setCategories(updatedCategories);

    try {
      await categoryService.updateRange(updatedCategories);
      toast.current.show({
        severity: "success",
        summary: "Başarılı",
        detail: "Kategoriler başarıyla yeniden sıralandı",
        life: 3000,
      });
    } catch (error) {
      console.error("Reorder failed", error);
      toast.current.show({
        severity: "error",
        summary: "Hata",
        detail: "Kategorileri yeniden sıralama başarısız",
        life: 3000,
      });
    }
  };

  const actionTemplateCategory = (rowData) => {
    return (
      <div className="flex ">
        <Button
          icon="pi pi-pencil"
          severity="warning"
          onClick={() => {
            setSelectedCategory(rowData);
            setVisibleCategoryDialog(true);
          }}
        />
        <Button
          className="ml-1"
          icon="pi pi-trash"
          disabled={rowData && rowData.products && rowData.products[0]}
          severity="danger"
          onClick={() => {
            setSelectedCategory(rowData);
            setVisibleConfirmDeleteCategoryDialog(true);
          }}
        />
      </div>
    );
  };

  const confirmDeleteCategory = async () => {
    const response = await categoryService.delete(selectedCategory.id);
    if (response) {
      refresh();
      toast.current.show({
        severity: "success",
        summary: "Başarılı",
        detail: "İşlem başarı ile tamamlandı",
        life: 3000,
      });
    }
    setVisibleConfirmDeleteCategoryDialog(false);
    setSelectedCategory({
      name: "",
      description: "",
      image: "",
    });
  };

  const customBase64UploaderForCategory = async (event) => {
    const file = event.files[0];
    const reader = new FileReader();
    const blob = await fetch(file.objectURL).then((response) =>
      response.blob()
    );

    reader.readAsDataURL(blob);

    reader.onloadend = async () => {
      const base64data = reader.result;
      if (base64data) {
        setSelectedCategory({
          ...selectedCategory,
          image: base64data,
        });
      }
    };
  };

  const submitCategory = async () => {
    if (!validateCategoryForm()) {
      toast.current.show({
        severity: "error",
        summary: "Hata",
        detail: "Lütfen tüm zorunlu alanları doldurun.",
        life: 3000,
      });
      return;
    }

    if (selectedCategory.id) {
      const response = await categoryService.update(
        selectedCategory.id,
        selectedCategory
      );

      if (response) {
        refresh();
        setVisibleCategoryDialog(false);
        toast.current.show({
          severity: "success",
          summary: "Başarılı",
          detail: "İşlem başarı ile tamamlandı",
          life: 3000,
        });
      }
    } else {
      const response = await categoryService.create(selectedCategory);
      if (response) {
        refresh();
        setVisibleCategoryDialog(false);
        toast.current.show({
          severity: "success",
          summary: "Başarılı",
          detail: "İşlem başarı ile tamamlandı",
          life: 3000,
        });
      }
    }
  };

  const updateCategoryStatus = async (category, value) => {
    const updatedCategory = { ...category, status: value === true ? 1 : 0 };
    try {
      await categoryService.update(category.id, updatedCategory);
      refresh();
      toast.current.show({
        severity: "success",
        summary: "Başarılı",
        detail: "Kategori durumu başarıyla güncellendi",
        life: 3000,
      });
    } catch (error) {
      console.error("Status update failed", error);
      toast.current.show({
        severity: "error",
        summary: "Hata",
        detail: "Kategori durumu güncelleme başarısız",
        life: 3000,
      });
    }
  };

  return (
    <Fragment>
      <Toast ref={toast} />
      <div>
        <div className="card ">
          <div className="flex justify-content-between flex-wrap">
            <div className="flex align-items-center justify-content-center">
              <Button
                className="mb-4"
                severity="secondary"
                icon="pi pi-refresh"
                label="Yenile"
                onClick={() => {
                  refresh();
                }}
              />
              <Button
                className="mb-4 ml-1"
                severity="success"
                icon="pi pi-plus"
                label="Yeni Kategori Ekle"
                onClick={() => {
                  setVisibleCategoryDialog(true);
                }}
              />
            </div>
          </div>
          <DataTable
            value={categories}
            reorderableRows
            onRowReorder={(e) => onRowReorderCategory(e)}
            paginator
            rows={5}
            rowsPerPageOptions={[5, 10, 25, 50]}
          >
            <Column rowReorder style={{ width: "3em" }} />
            <Column sortable field="name" header="Kategori Adı" />
            <Column field="description" header="Kategori Açıklaması" />
            <Column
              header="Durum"
              body={(row) => {
                return row ? (
                  <InputSwitch
                    checked={row.status === 1 ? true : false}
                    onChange={(e) => updateCategoryStatus(row, e.value)}
                  />
                ) : null;
              }}
            />
            <Column body={actionTemplateCategory} style={{ width: "3em" }} />
          </DataTable>
        </div>
      </div>
      <Dialog
        header={
          selectedCategory && selectedCategory.id
            ? "Kategori Güncelleme"
            : "Kategori Ekleme"
        }
        visible={visibleCategoryDialog}
        className="w-full lg:w-8"
        onHide={() => {
          setSelectedCategory({
            name: "",
            description: "",
            image: "",
          });
          setVisibleCategoryDialog(false);
        }}
      >
        <div className="p-5 border-round flex-auto">
          <div className="flex gap-5 flex-column-reverse md:flex-row">
            <div className="flex-auto p-fluid">
              <div className="mb-4">
                <label className="block font-medium text-900 mb-3">
                  Kategori Görseli
                </label>
                <Image
                  preview
                  width="200"
                  src={
                    selectedCategory && selectedCategory.image
                      ? selectedCategory.image
                      : null
                  }
                />
                <FileUpload
                  mode="basic"
                  url="/api/upload"
                  accept="image/*"
                  customUpload
                  chooseLabel="Resim Seç"
                  onSelect={customBase64UploaderForCategory}
                  className={categoryFormErrors.image ? "p-info" : ""}
                />
              </div>
              <div className="mb-4">
                <label className="block font-medium text-900 mb-2">
                  Kategori Adı
                </label>
                <InputText
                  value={
                    selectedCategory && selectedCategory.name
                      ? selectedCategory.name
                      : ""
                  }
                  onChange={(e) => {
                    setSelectedCategory({
                      ...selectedCategory,
                      name: e.target.value,
                    });
                  }}
                  type="text"
                  className={categoryFormErrors.name ? "p-info" : ""}
                />
                {categoryFormErrors.name && (
                  <small className="p-info">{categoryFormErrors.name}</small>
                )}
              </div>
              <div className="mb-4">
                <label className="block font-medium text-900 mb-2">
                  Kategori Açıklaması
                </label>
                <InputTextarea
                  value={
                    selectedCategory && selectedCategory.description
                      ? selectedCategory.description
                      : ""
                  }
                  type="text"
                  rows="5"
                  onChange={(e) => {
                    setSelectedCategory({
                      ...selectedCategory,
                      description: e.target.value,
                    });
                  }}
                  autoResize
                  className={categoryFormErrors.description ? "p-info" : ""}
                ></InputTextarea>
                {categoryFormErrors.description && (
                  <small className="p-info">
                    {categoryFormErrors.description}
                  </small>
                )}
              </div>

              <div>
                <Button
                  label="Kaydet"
                  onClick={() => submitCategory()}
                  className="p-ripple w-auto"
                ></Button>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
      <Dialog
        header="Silme İşlemi Onayı"
        visible={visibleConfirmDeleteCategoryDialog}
        className="w-full lg:w-3"
        footer={
          <div>
            <Button
              label="Hayır"
              icon="pi pi-times"
              onClick={() => setVisibleConfirmDeleteCategoryDialog(false)}
              className="p-button-text"
            />
            <Button
              label="Evet"
              icon="pi pi-check"
              onClick={confirmDeleteCategory}
              autoFocus
            />
          </div>
        }
        onHide={() => setVisibleConfirmDeleteCategoryDialog(false)}
      >
        <p>Bu kategoriyi silmek istediğinizden emin misiniz?</p>
      </Dialog>
    </Fragment>
  );
};

export default Categories;
