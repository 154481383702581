import React, { Fragment } from "react";
import { Navigate, BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./layout/layout";
import PrivateRouteManager from "./utils/managers/privateRouteManager";

/*PAGES*/
import Login from "./pages/auth/login";
import Home from "./pages/dashboard/home";
import Categories from "./pages/category/categories";
import Products from "./pages/product/products";
import Events from "./pages/event/events";
import Games from "./pages/game/games";
import Members from "./pages/member/members";
import Blogs from "./pages/blog/blogs";
import Albums from "./pages/album/albums";
import Settings from "./pages/settings/settings";

const App = () => {
  return (
    <Fragment>
      <BrowserRouter>
        <Routes>
          <Route element={<Layout />}>
            <Route
              path="/"
              element={
                <PrivateRouteManager>
                  <Home />
                </PrivateRouteManager>
              }
            />
            <Route
              path="/categories"
              element={
                <PrivateRouteManager>
                  <Categories />
                </PrivateRouteManager>
              }
            />
            <Route
              path="/products"
              element={
                <PrivateRouteManager>
                  <Products />
                </PrivateRouteManager>
              }
            />
            <Route
              path="/events"
              element={
                <PrivateRouteManager>
                  <Events />
                </PrivateRouteManager>
              }
            />
            <Route
              path="/games"
              element={
                <PrivateRouteManager>
                  <Games />
                </PrivateRouteManager>
              }
            />
            <Route
              path="/members"
              element={
                <PrivateRouteManager>
                  <Members />
                </PrivateRouteManager>
              }
            />
            <Route
              path="/albums"
              element={
                <PrivateRouteManager>
                  <Albums />
                </PrivateRouteManager>
              }
            />
            <Route
              path="/blogs"
              element={
                <PrivateRouteManager>
                  <Blogs />
                </PrivateRouteManager>
              }
            />
            <Route
              path="/settings"
              element={
                <PrivateRouteManager>
                  <Settings />
                </PrivateRouteManager>
              }
            />
          </Route>

          <Route path="/login" element={<Login />}></Route>
          <Route index path="*" element={<Navigate to="/" replace />}></Route>
        </Routes>
      </BrowserRouter>
    </Fragment>
  );
};

export default App;
