import axiosInstance from "./axiosInstance";

const albumService = {
  getAll: async () => {
    try {
      const response = await axiosInstance.get("/album");
      return response.data;
    } catch (error) {
      console.error("Error getting album:", error);
    }
  },

  get: async (id) => {
    try {
      const response = await axiosInstance.get(`/album/${id}`);
      return response.data;
    } catch (error) {
      console.error(`Error getting album with id ${id}:`, error);
    }
  },

  create: async (albumData) => {
    try {
      const response = await axiosInstance.post("/album", albumData);
      return response;
    } catch (error) {
      console.error("Error creating album:", error);
    }
  },

  update: async (id, albumData) => {
    try {
      const response = await axiosInstance.put(`/album/${id}`, albumData);
      return response;
    } catch (error) {
      console.error(`Error updating album with id ${id}:`, error);
    }
  },

  updateRange: async (albums) => {
    try {
      const response = await axiosInstance.put(`/album/album/range`, albums);
      return response;
    } catch (error) {
      console.error(`Error updating albums range`, error);
    }
  },

  delete: async (id) => {
    try {
      const response = await axiosInstance.delete(`/album/${id}`);
      return response;
    } catch (error) {
      console.error(`Error deleting album with id ${id}:`, error);
    }
  },
};

export default albumService;
