import React, { useState, useEffect } from "react";
import categoryService from "../../services/categoryService";
import eventService from "../../services/eventService";
import productService from "../../services/productService";
import memberService from "../../services/memberService";
import blogService from "../../services/blogService";
import albumService from "../../services/albumService";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const navigate = useNavigate();
  const [categories, setCategories] = useState(null);
  const [products, setProducts] = useState(null);
  const [event, setEvents] = useState(null);
  const [members, setMembers] = useState(null);
  const [album, setAlbums] = useState(null);
  const [blogs, setBlogs] = useState(null);

  useEffect(() => {
    const fetchProducts = async () => {
      const response = await productService.getAll();
      if (response) {
        setProducts(response);
      }
    };

    const fetchCategories = async () => {
      const response = await categoryService.getAll();
      if (response) {
        setCategories(response);
      }
    };

    const fetchEvents = async () => {
      const response = await eventService.getAll();
      if (response) {
        setEvents(response);
      }
    };

    const fetchMembers = async () => {
      const response = await memberService.getAll();
      if (response) {
        setMembers(response);
      }
    };

    const fetchBlogs = async () => {
      const response = await blogService.getAll();
      if (response) {
        setBlogs(response);
      }
    };

    const fetchAlbums = async () => {
      const response = await albumService.getAll();
      if (response) {
        setAlbums(response);
      }
    };

    fetchCategories();
    fetchProducts();
    fetchEvents();
    fetchMembers();
    fetchBlogs();
    fetchAlbums();
  }, []);

  return (
    <div className="col-12">
      <div className="grid">
        <div
          onClick={() => navigate("/categories")}
          className="cursor-pointer col-12 md:col-6 lg:col-4 p-3"
        >
          <div className="p-3 text-center bg-blue-500 border-round">
            <span
              className="inline-flex justify-content-center align-items-center bg-blue-600 border-circle mb-3"
              style={{ width: 49, height: 49 }}
            >
              <i className="pi pi-link text-xl text-white"></i>
            </span>
            <div className="text-5xl font-medium text-white mb-2">
              {categories && categories.length}
            </div>
            <span className="text-blue-100 font-medium">Kategoriler</span>
          </div>
        </div>
        <div
          onClick={() => navigate("/products")}
          className="cursor-pointer col-12 md:col-6 lg:col-4 p-3"
        >
          <div className="p-3 text-center bg-purple-500 border-round">
            <span
              className="inline-flex justify-content-center align-items-center bg-purple-600 border-circle mb-3"
              style={{ width: 49, height: 49 }}
            >
              <i className="pi pi-link text-xl text-white"></i>
            </span>
            <div className="text-5xl font-medium text-white mb-2">
              {products && products.length}
            </div>
            <span className="text-purple-100 font-medium">Ürünler</span>
          </div>
        </div>
        <div
          onClick={() => navigate("/events")}
          className="cursor-pointer col-12 md:col-6 lg:col-4 p-3"
        >
          <div className="p-3 text-center bg-indigo-500 border-round">
            <span
              className="inline-flex justify-content-center align-items-center bg-indigo-600 border-circle mb-3"
              style={{ width: 49, height: 49 }}
            >
              <i className="pi pi-link text-xl text-white"></i>
            </span>
            <div className="text-5xl font-medium text-white mb-2">
              {event && event.length}
            </div>
            <span className="text-indigo-100 font-medium">Etkinlikler</span>
          </div>
        </div>
        <div
          onClick={() => navigate("/members")}
          className="cursor-pointer col-12 md:col-6 lg:col-4 p-3"
        >
          <div className="p-3 text-center bg-indigo-800 border-round">
            <span
              className="inline-flex justify-content-center align-items-center bg-indigo-600 border-circle mb-3"
              style={{ width: 49, height: 49 }}
            >
              <i className="pi pi-link text-xl text-white"></i>
            </span>
            <div className="text-5xl font-medium text-white mb-2">
              {members && members.length}
            </div>
            <span className="text-indigo-100 font-medium">Ekibimiz</span>
          </div>
        </div>
        <div
          onClick={() => navigate("/blogs")}
          className="cursor-pointer col-12 md:col-6 lg:col-4 p-3"
        >
          <div className="p-3 text-center bg-indigo-900 border-round">
            <span
              className="inline-flex justify-content-center align-items-center bg-indigo-600 border-circle mb-3"
              style={{ width: 49, height: 49 }}
            >
              <i className="pi pi-link text-xl text-white"></i>
            </span>
            <div className="text-5xl font-medium text-white mb-2">
              {blogs && blogs.length}
            </div>
            <span className="text-indigo-100 font-medium">Bloglar</span>
          </div>
        </div>
        <div
          onClick={() => navigate("/gallery")}
          className="cursor-pointer col-12 md:col-6 lg:col-4 p-3"
        >
          <div className="p-3 text-center bg-indigo-700 border-round">
            <span
              className="inline-flex justify-content-center align-items-center bg-indigo-600 border-circle mb-3"
              style={{ width: 49, height: 49 }}
            >
              <i className="pi pi-link text-xl text-white"></i>
            </span>
            <div className="text-5xl font-medium text-white mb-2">
              {album && album.length}
            </div>
            <span className="text-indigo-100 font-medium">Albümler</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
