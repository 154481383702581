import axiosInstance from "./axiosInstance";

const tMediaService = {
  get: async (id) => {
    try {
      const response = await axiosInstance.get(`/tmedia/${id}`);
      return response.data;
    } catch (error) {
      console.error(`Error getting tmedia with id ${id}:`, error);
    }
  },

  create: async (tmediaData) => {
    try {
      const response = await axiosInstance.post("/tmedia", tmediaData);
      return response;
    } catch (error) {
      console.error("Error creating tmedia:", error);
    }
  },

  delete: async (id) => {
    try {
      const response = await axiosInstance.delete(`/tmedia/${id}`);
      return response;
    } catch (error) {
      console.error(`Error deleting tmedia with id ${id}:`, error);
    }
  },
};

export default tMediaService;
