import React, { Fragment, useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { FileUpload } from "primereact/fileupload";
import { Editor } from "primereact/editor";
import memberService from "../../services/memberService";
import { Toast } from "primereact/toast";
import { Image } from "primereact/image";
import { InputSwitch } from "primereact/inputswitch";

const Members = () => {
  const toast = useRef();
  const [visibleMemberDialog, setVisibleMemberDialog] = useState(false);
  const [
    visibleConfirmDeleteMemberDialog,
    setVisibleConfirmDeleteMemberDialog,
  ] = useState(false);
  const [selectedMember, setSelectedMember] = useState({
    name: "",
    description: "",
    image: "",
  });
  const [members, setMembers] = useState(null);
  const [memberFormErrors, setMemberFormErrors] = useState({});

  useEffect(() => {
    refresh();
  }, []);

  const refresh = async () => {
    const response = await memberService.getAll();
    if (response) {
      setMembers(response);
    }
  };

  const validateMemberForm = () => {
    const errors = {};
    if (!selectedMember.name) {
      errors.name = "Ekip Üye adı zorunludur.";
    }
    if (!selectedMember.surname) {
      errors.surname = "Ekip Üye Soyadı zorunludur.";
    }
    if (!selectedMember.description) {
      errors.description = "Ekip Üye açıklaması zorunludur.";
    }

    setMemberFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const onRowReorderMember = async (event) => {
    const reorderedMembers = event.value;
    const updatedMembers = reorderedMembers.map((member, index) => {
      return { ...member, position: index + 1 };
    });

    setMembers(updatedMembers);

    try {
      await memberService.updateRange(updatedMembers);
      toast.current.show({
        severity: "success",
        summary: "Başarılı",
        detail: "Ekip Üyeler başarıyla yeniden sıralandı",
        life: 3000,
      });
    } catch (error) {
      console.error("Reorder failed", error);
      toast.current.show({
        severity: "error",
        summary: "Hata",
        detail: "Ekip Üyeleri yeniden sıralama başarısız",
        life: 3000,
      });
    }
  };

  const actionTemplateMember = (rowData) => {
    return (
      <div className="flex ">
        <Button
          icon="pi pi-pencil"
          severity="warning"
          onClick={() => {
            setSelectedMember(rowData);
            setVisibleMemberDialog(true);
          }}
        />
        <Button
          className="ml-1"
          icon="pi pi-trash"
          severity="danger"
          onClick={() => {
            setSelectedMember(rowData);
            setVisibleConfirmDeleteMemberDialog(true);
          }}
        />
      </div>
    );
  };

  const confirmDeleteMember = async () => {
    const response = await memberService.delete(selectedMember.id);
    if (response) {
      refresh();
      toast.current.show({
        severity: "success",
        summary: "Başarılı",
        detail: "İşlem başarı ile tamamlandı",
        life: 3000,
      });
    }
    setVisibleConfirmDeleteMemberDialog(false);
    setSelectedMember({
      name: "",
      description: "",
      image: "",
    });
  };

  const customBase64UploaderForMember = async (event) => {
    const file = event.files[0];
    const reader = new FileReader();
    const blob = await fetch(file.objectURL).then((response) =>
      response.blob()
    );

    reader.readAsDataURL(blob);

    reader.onloadend = async () => {
      const base64data = reader.result;
      if (base64data) {
        setSelectedMember({
          ...selectedMember,
          image: base64data,
        });
      }
    };
  };

  const submitMember = async () => {
    if (!validateMemberForm()) {
      toast.current.show({
        severity: "error",
        summary: "Hata",
        detail: "Lütfen tüm zorunlu alanları doldurun.",
        life: 3000,
      });
      return;
    }

    if (selectedMember.id) {
      const response = await memberService.update(
        selectedMember.id,
        selectedMember
      );

      if (response) {
        refresh();
        setVisibleMemberDialog(false);
        toast.current.show({
          severity: "success",
          summary: "Başarılı",
          detail: "İşlem başarı ile tamamlandı",
          life: 3000,
        });
      }
    } else {
      const response = await memberService.create(selectedMember);
      if (response) {
        refresh();
        setVisibleMemberDialog(false);
        toast.current.show({
          severity: "success",
          summary: "Başarılı",
          detail: "İşlem başarı ile tamamlandı",
          life: 3000,
        });
      }
    }
  };

  const updateMemberStatus = async (member, value) => {
    const updatedMember = { ...member, status: value === true ? 1 : 0 };
    try {
      await memberService.update(member.id, updatedMember);
      refresh();
      toast.current.show({
        severity: "success",
        summary: "Başarılı",
        detail: "Ekip Üye durumu başarıyla güncellendi",
        life: 3000,
      });
    } catch (error) {
      console.error("Status update failed", error);
      toast.current.show({
        severity: "error",
        summary: "Hata",
        detail: "Ekip Üye durumu güncelleme başarısız",
        life: 3000,
      });
    }
  };

  return (
    <Fragment>
      <Toast ref={toast} />
      <div>
        <div className="card ">
          <div className="flex justify-content-between flex-wrap">
            <div className="flex align-items-center justify-content-center">
              <Button
                className="mb-4"
                severity="secondary"
                icon="pi pi-refresh"
                label="Yenile"
                onClick={() => {
                  refresh();
                }}
              />
              <Button
                className="mb-4 ml-1"
                severity="warning"
                icon="pi pi-plus"
                label="Yeni Ekip Üye Ekle"
                onClick={() => {
                  setVisibleMemberDialog(true);
                }}
              />
            </div>
          </div>
          <DataTable
            value={members}
            reorderableRows
            onRowReorder={(e) => onRowReorderMember(e)}
            paginator
            rows={5}
            rowsPerPageOptions={[5, 10, 25, 50]}
          >
            <Column rowReorder style={{ width: "3em" }} />
            <Column
              sortable
              field="name"
              header="Ekip Üye Adı"
              style={{ width: "10em" }}
            />
            <Column
              sortable
              field="surname"
              header="Ekip Üye Soyadı"
              style={{ width: "12em" }}
            />
            <Column
              sortable
              field="role"
              header="Ekip Üye Rolü"
              style={{ width: "12em" }}
            />
            <Column
              header="Durum"
              body={(row) => {
                return row ? (
                  <InputSwitch
                    checked={row.status === 1 ? true : false}
                    onChange={(e) => updateMemberStatus(row, e.value)}
                  />
                ) : null;
              }}
            />
            <Column body={actionTemplateMember} style={{ width: "3em" }} />
          </DataTable>
        </div>
      </div>
      <Dialog
        header={
          selectedMember && selectedMember.id
            ? "Ekip Üye Güncelleme"
            : "Ekip Üye Ekleme"
        }
        visible={visibleMemberDialog}
        className="w-full lg:w-8"
        onHide={() => {
          setSelectedMember({
            name: "",
            description: "",
            image: "",
          });
          setVisibleMemberDialog(false);
        }}
      >
        <div className="p-5 border-round flex-auto">
          <div className="flex gap-5 flex-column-reverse md:flex-row">
            <div className="flex-auto p-fluid">
              <div className="mb-4">
                <label className="block font-medium text-900 mb-3">
                  Ekip Üye Görseli
                </label>
                <Image
                  preview
                  width="200"
                  src={
                    selectedMember && selectedMember.image
                      ? selectedMember.image
                      : null
                  }
                />
                <FileUpload
                  mode="basic"
                  url="/api/upload"
                  accept="image/*"
                  customUpload
                  chooseLabel="Resim Seç"
                  onSelect={customBase64UploaderForMember}
                  className={memberFormErrors.image ? "p-info" : ""}
                />
              </div>
              <div className="mb-4">
                <label className="block font-medium text-900 mb-2">
                  Ekip Üye Adı
                </label>
                <InputText
                  value={
                    selectedMember && selectedMember.name
                      ? selectedMember.name
                      : ""
                  }
                  onChange={(e) => {
                    setSelectedMember({
                      ...selectedMember,
                      name: e.target.value,
                    });
                  }}
                  type="text"
                  className={memberFormErrors.name ? "p-info" : ""}
                />
                {memberFormErrors.name && (
                  <small className="p-info">{memberFormErrors.name}</small>
                )}
              </div>
              <div className="mb-4">
                <label className="block font-medium text-900 mb-2">
                  Ekip Üye Soyadı
                </label>
                <InputText
                  value={
                    selectedMember && selectedMember.surname
                      ? selectedMember.surname
                      : ""
                  }
                  onChange={(e) => {
                    setSelectedMember({
                      ...selectedMember,
                      surname: e.target.value,
                    });
                  }}
                  type="text"
                  className={memberFormErrors.surname ? "p-info" : ""}
                />
                {memberFormErrors.surname && (
                  <small className="p-info">{memberFormErrors.surname}</small>
                )}
              </div>
              <div className="mb-4">
                <label className="block font-medium text-900 mb-2">
                  Ekip Üye Ünvanı
                </label>
                <InputText
                  value={
                    selectedMember && selectedMember.role
                      ? selectedMember.role
                      : ""
                  }
                  onChange={(e) => {
                    setSelectedMember({
                      ...selectedMember,
                      role: e.target.value,
                    });
                  }}
                  type="text"
                />
              </div>
              <div className="mb-4">
                <label className="block font-medium text-900 mb-2">
                  Ekip Üye Açıklaması
                </label>
                <Editor
                  className={memberFormErrors.description ? "p-info" : ""}
                  value={
                    selectedMember && selectedMember.description
                      ? selectedMember.description
                      : ""
                  }
                  onTextChange={(e) =>
                    setSelectedMember({
                      ...selectedMember,
                      description: e.htmlValue,
                    })
                  }
                  style={{ height: "320px" }}
                />
                {memberFormErrors.description && (
                  <small className="p-info">
                    {memberFormErrors.description}
                  </small>
                )}
              </div>

              <div className="mb-4">
                <label className="block font-medium text-900 mb-2">
                  Instagram Bağlantı Adresi
                </label>
                <InputText
                  value={
                    selectedMember && selectedMember.instagramAddress
                      ? selectedMember.instagramAddress
                      : ""
                  }
                  onChange={(e) => {
                    setSelectedMember({
                      ...selectedMember,
                      instagramAddress: e.target.value,
                    });
                  }}
                />
              </div>

              <div className="mb-4">
                <label className="block font-medium text-900 mb-2">
                  Facebook Bağlantı Adresi
                </label>
                <InputText
                  value={
                    selectedMember && selectedMember.facebookAddress
                      ? selectedMember.facebookAddress
                      : ""
                  }
                  onChange={(e) => {
                    setSelectedMember({
                      ...selectedMember,
                      facebookAddress: e.target.value,
                    });
                  }}
                />
              </div>

              <div className="mb-4">
                <label className="block font-medium text-900 mb-2">
                  Twitter Bağlantı Adresi
                </label>
                <InputText
                  value={
                    selectedMember && selectedMember.twitterAddress
                      ? selectedMember.twitterAddress
                      : ""
                  }
                  onChange={(e) => {
                    setSelectedMember({
                      ...selectedMember,
                      twitterAddress: e.target.value,
                    });
                  }}
                />
              </div>

              <div>
                <Button
                  label="Kaydet"
                  onClick={() => submitMember()}
                  className="p-ripple w-auto"
                ></Button>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
      <Dialog
        header="Silme İşlemi Onayı"
        visible={visibleConfirmDeleteMemberDialog}
        className="w-full lg:w-3"
        footer={
          <div>
            <Button
              label="Hayır"
              icon="pi pi-times"
              onClick={() => setVisibleConfirmDeleteMemberDialog(false)}
              className="p-button-text"
            />
            <Button
              label="Evet"
              icon="pi pi-check"
              onClick={confirmDeleteMember}
              autoFocus
            />
          </div>
        }
        onHide={() => setVisibleConfirmDeleteMemberDialog(false)}
      >
        <p>Bu ekip üyesini silmek istediğinizden emin misiniz?</p>
      </Dialog>
    </Fragment>
  );
};

export default Members;
