import React, { useState, Fragment, useEffect, useRef } from "react";
import { FileUpload } from "primereact/fileupload";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import { InputText } from "primereact/inputtext";
import { Divider } from "primereact/divider";
import { Toast } from "primereact/toast";
import { Ripple } from "primereact/ripple";
import { Image } from "primereact/image";
import constantService from "../../services/constantService";
import { Editor } from "primereact/editor";

const Settings = () => {
  const toast = useRef();
  const fileUploadRef = useRef(null);
  const [selectedTab, setSelectedTab] = useState(1);
  const [aboutUs, setAboutUs] = useState(null);
  const [scene, setScene] = useState(null);

  useEffect(() => {
    const fetchAbout = async () => {
      const response = await constantService.getAboutUsData();

      if (response) {
        setAboutUs(response);
      }
    };

    const fetchScene = async () => {
      const response = await constantService.getSceneData();

      if (response) {
        setScene(response);
      }
    };

    fetchAbout();

    fetchScene();
  }, []);

  const getTabClass = (tabIndex) => {
    return tabIndex === selectedTab
      ? "surface-hover p-ripple flex align-items-center cursor-pointer p-3 border-round text-800 transition-duration-150 transition-colors"
      : "surface-ground p-ripple flex align-items-center cursor-pointer p-3 border-round text-800 transition-duration-150 transition-colors";
  };

  const update = async (type) => {
    if (type === "aboutUs") {
      const response = constantService.updateAboutUs(aboutUs);

      if (response) {
        toast.current.show({
          severity: "success",
          summary: "Başarılı",
          detail: "Hakkımızda güncellendi.",
          life: 3000,
        });
      } else {
        toast.current.show({
          severity: "danger",
          summary: "Başarısız",
          detail: "Hakkımızda güncellenemedi",
          life: 3000,
        });
      }
    } else {
      const response = constantService.updateScene(scene);

      if (response) {
        toast.current.show({
          severity: "success",
          summary: "Başarılı",
          detail: "Sahne güncellendi.",
          life: 3000,
        });
      } else {
        toast.current.show({
          severity: "danger",
          summary: "Başarısız",
          detail: "Sahne güncellenemedi",
          life: 3000,
        });
      }
    }
  };

  const customBase64Uploader = async (event, type) => {
    const file = event.files[0];
    const reader = new FileReader();
    const blob = await fetch(file.objectURL).then((response) =>
      response.blob()
    );

    reader.readAsDataURL(blob);

    reader.onloadend = async () => {
      const base64data = reader.result;
      if (base64data) {
        if (type === "aboutUs") {
          setAboutUs({
            ...aboutUs,
            image: base64data,
          });
        } else {
          setScene({
            ...scene,
            image: base64data,
          });
        }
      }
    };

    fileUploadRef.current.clear();
  };

  return (
    <Fragment>
      <Toast ref={toast} />
      <div className="px-0 py-0 md:px-6 lg:px-8">
        <div className="p-fluid flex flex-column lg:flex-row">
          <ul className="list-none m-0 p-0 flex flex-row lg:flex-column justify-content-evenly md:justify-content-between lg:justify-content-start mb-5 lg:pr-5 lg:mb-0">
            <li onClick={() => setSelectedTab(1)} className="mb-2">
              <a className={getTabClass(1)}>
                <i className="pi pi-hashtag md:mr-2"></i>
                <span className="font-medium hidden md:block">Hakkımızda</span>
                <Ripple />
              </a>
            </li>
            <li onClick={() => setSelectedTab(2)}>
              <a className={getTabClass(2)}>
                <i className="pi pi-lightbulb md:mr-2"></i>
                <span className="font-medium hidden md:block">Sahne</span>
                <Ripple />
              </a>
            </li>
          </ul>

          {selectedTab === 1 ? (
            <div className="surface-ground p-5 shadow-2 border-round flex-auto ">
              <div className="text-900 font-semibold text-lg mt-3">
                Hakkımızda
              </div>
              <Divider></Divider>
              <div className="flex gap-5 flex-column-reverse md:flex-row">
                <div className="flex-auto p-fluid">
                  <div className="mb-4">
                    <label
                      htmlFor="bio"
                      className="block font-medium text-900 mb-3"
                    >
                      Açıklama Yazısı
                    </label>
                    <Editor
                      value={
                        aboutUs && aboutUs.description
                          ? aboutUs.description
                          : null
                      }
                      onTextChange={(e) =>
                        setAboutUs({ ...aboutUs, description: e.htmlValue })
                      }
                      style={{ height: "320px" }}
                    />
                  </div>

                  <div>
                    <Button
                      label="Güncelle"
                      className="p-ripple w-auto"
                      onClick={() => update("aboutUs")}
                    ></Button>
                  </div>
                </div>
                <div className="flex flex-column align-items-center flex-or">
                  <span className="font-medium text-900 mb-2">Resim</span>
                  <Image
                    src={aboutUs && aboutUs.image ? aboutUs.image : null}
                    imageClassName="h-10rem w-10rem"
                    preview
                  />
                  <FileUpload
                    ref={fileUploadRef}
                    mode="basic"
                    url="/api/upload"
                    className="mt-2"
                    accept="image/*"
                    customUpload
                    chooseLabel="Resim Seç"
                    onSelect={(e) => customBase64Uploader(e, "aboutUs")}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className="surface-ground  p-5 shadow-2 border-round flex-auto">
              <div className="text-900 font-semibold text-lg mt-3">Sahne</div>
              <Divider></Divider>
              <div className="flex gap-5 flex-column-reverse md:flex-row">
                <div className="flex-auto p-fluid">
                  <div className="mb-4">
                    <label
                      htmlFor="email"
                      className="block font-medium text-900 mb-2"
                    >
                      Başlık
                    </label>
                    <InputText
                      value={scene && scene.title ? scene.title : null}
                    />
                  </div>
                  <div className="mb-4">
                    <label
                      htmlFor="bio"
                      className="block font-medium text-900 mb-2"
                    >
                      Açıklama Yazısı
                    </label>
                    <Editor
                      value={
                        scene && scene.description ? scene.description : null
                      }
                      onTextChange={(e) =>
                        setScene({ ...scene, description: e.htmlValue })
                      }
                      style={{ height: "320px" }}
                    />
                  </div>

                  <div>
                    <Button
                      label="Güncelle"
                      onClick={() => update("scene")}
                      className="p-ripple w-auto"
                    ></Button>
                  </div>
                </div>
                <div className="flex flex-column align-items-center flex-or">
                  <span className="font-medium text-900 mb-2">Resim</span>
                  <Image
                    src={scene && scene.image ? scene.image : null}
                    imageClassName="h-10rem w-10rem"
                    preview
                  />
                  <FileUpload
                    ref={fileUploadRef}
                    mode="basic"
                    url="/api/upload"
                    className="mt-2"
                    accept="image/*"
                    customUpload
                    chooseLabel="Resim Seç"
                    onSelect={(e) => customBase64Uploader(e, "scene")}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default Settings;
