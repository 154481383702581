import React, { Fragment, useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { Dialog } from "primereact/dialog";
import { FileUpload } from "primereact/fileupload";
import { Editor } from "primereact/editor";
import eventService from "../../services/eventService";
import { Toast } from "primereact/toast";
import { Image } from "primereact/image";
import { InputSwitch } from "primereact/inputswitch";
import { Calendar } from "primereact/calendar";
import { addLocale } from "primereact/api";
import { Dropdown } from "primereact/dropdown";

const Events = () => {
  const toast = useRef();
  const [visibleEventDialog, setVisibleEventDialog] = useState(false);
  const [visibleConfirmDeleteEventDialog, setVisibleConfirmDeleteEventDialog] =
    useState(false);
  const [selectedEvent, setSelectedEvent] = useState({
    name: "",
    description: "",
    image: "",
    price: "",
    categoryId: "",
  });
  const [events, setEvents] = useState(null);
  const [eventFormErrors, setEventFormErrors] = useState({});

  addLocale("tr", {
    firstDayOfWeek: 0,
    showMonthAfterYear: true,
    dayNames: [
      "pazartesi",
      "salı",
      "çarşamba",
      "perşembe",
      "cuma",
      "cumartesi",
      "pazar",
    ],
    dayNamesShort: ["pzt", "sal", "çar", "per", "cum", "cmt", "paz"],
    dayNamesMin: ["PTS", "SAL", "ÇAR", "PER", "CUM", "CMT", "PAZ"],
    monthNames: [
      "Ocak",
      "Şubat",
      "Mart",
      "Nisan",
      "Mayıs",
      "Haziran",
      "Temmuz",
      "Ağustos",
      "Eylül",
      "Ekim",
      "Kasım",
      "Aralık",
    ],
    monthNamesShort: [
      "oca",
      "şub",
      "mar",
      "nis",
      "may",
      "haz",
      "tem",
      "ağu",
      "eyl",
      "ek",
      "kas",
      "ara",
    ],
    today: "Bugün",
    clear: "Temizle",
  });

  useEffect(() => {
    refresh();
  }, []);

  const refresh = async () => {
    const fetchEvents = async () => {
      const response = await eventService.getAll();
      if (response) {
        setEvents(response);
      }
    };

    fetchEvents();
  };

  const countries = [
    { id: "Ankara", name: "Ankara" },
    { id: "İstanbul", name: "İstanbul" },
    { id: "Eskişehir", name: "Eskişehir" },
    { id: "Diğer", name: "Diğer" },
  ];

  const validateEventForm = () => {
    const errors = {};
    if (!selectedEvent.name) {
      errors.name = "Etkinlik adı zorunludur.";
    }
    if (!selectedEvent.description) {
      errors.description = "Etkinlik açıklaması zorunludur.";
    }

    if (!selectedEvent.country) {
      errors.description = "Etkinlik şehiri zorunludur.";
    }
    if (!selectedEvent.price) {
      errors.price = "Etkinlik fiyatı zorunludur.";
    }
    setEventFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const onRowReorderEvent = async (event) => {
    const reorderedEvents = event.value;
    const updatedEvents = reorderedEvents.map((event, index) => {
      return { ...event, position: index + 1 };
    });

    setEvents(updatedEvents);

    try {
      await eventService.updateRange(updatedEvents);
      toast.current.show({
        severity: "success",
        summary: "Başarılı",
        detail: "Etkinlikler başarıyla yeniden sıralandı",
        life: 3000,
      });
    } catch (error) {
      console.error("Reorder failed", error);
      toast.current.show({
        severity: "error",
        summary: "Hata",
        detail: "Etkinlikleri yeniden sıralama başarısız",
        life: 3000,
      });
    }
  };

  const actionTemplateEvent = (rowData) => {
    return (
      <div className="flex">
        <Button
          icon="pi pi-pencil"
          severity="warning"
          onClick={() => {
            setSelectedEvent(rowData);
            setVisibleEventDialog(true);
          }}
        />
        <Button
          className="ml-1"
          icon="pi pi-trash"
          severity="danger"
          onClick={() => {
            setSelectedEvent(rowData);
            setVisibleConfirmDeleteEventDialog(true);
          }}
        />
      </div>
    );
  };

  const confirmDeleteEvent = async () => {
    const response = await eventService.delete(selectedEvent.id);
    if (response) {
      refresh();
      toast.current.show({
        severity: "success",
        summary: "Başarılı",
        detail: "İşlem başarı ile tamamlandı",
        life: 3000,
      });
    }
    setVisibleConfirmDeleteEventDialog(false);
    setSelectedEvent({
      name: "",
      description: "",
      image: "",
      price: "",
      categoryId: "",
    });
  };

  const customBase64UploaderForEvent = async (event) => {
    const file = event.files[0];
    const reader = new FileReader();
    const blob = await fetch(file.objectURL).then((response) =>
      response.blob()
    );

    reader.readAsDataURL(blob);

    reader.onloadend = async () => {
      const base64data = reader.result;
      if (base64data) {
        setSelectedEvent({
          ...selectedEvent,
          image: base64data,
        });
      }
    };
  };

  const submitEvent = async () => {
    if (!validateEventForm()) {
      toast.current.show({
        severity: "error",
        summary: "Hata",
        detail: "Lütfen tüm zorunlu alanları doldurun.",
        life: 3000,
      });
      return;
    }

    if (selectedEvent.id) {
      const response = await eventService.update(
        selectedEvent.id,
        selectedEvent
      );

      if (response) {
        refresh();
        setVisibleEventDialog(false);
        toast.current.show({
          severity: "success",
          summary: "Başarılı",
          detail: "İşlem başarı ile tamamlandı",
          life: 3000,
        });
      }
    } else {
      const response = await eventService.create(selectedEvent);
      if (response) {
        refresh();
        setVisibleEventDialog(false);
        toast.current.show({
          severity: "success",
          summary: "Başarılı",
          detail: "İşlem başarı ile tamamlandı",
          life: 3000,
        });
      }
    }
  };

  const updateEventStatus = async (event, value) => {
    const updatedEvent = { ...event, status: value === true ? 1 : 0 };
    try {
      await eventService.update(event.id, updatedEvent);
      refresh();
      toast.current.show({
        severity: "success",
        summary: "Başarılı",
        detail: "Etkinlik durumu başarıyla güncellendi",
        life: 3000,
      });
    } catch (error) {
      console.error("Status update failed", error);
      toast.current.show({
        severity: "error",
        summary: "Hata",
        detail: "Etkinlik durumu güncelleme başarısız",
        life: 3000,
      });
    }
  };

  return (
    <Fragment>
      <Toast ref={toast} />
      <div>
        <div className="card">
          <div className="flex justify-content-between flex-wrap">
            <div className="flex align-items-center justify-content-center">
              <Button
                className="mb-4"
                severity="secondary"
                icon="pi pi-refresh"
                label="Yenile"
                onClick={() => {
                  refresh();
                }}
              />
              <Button
                className="mb-4 ml-1"
                severity="help"
                icon="pi pi-plus"
                label="Yeni Etkinlik Ekle"
                onClick={() => {
                  setVisibleEventDialog(true);
                }}
              />
            </div>
          </div>
          <DataTable
            value={events}
            reorderableRows
            onRowReorder={(e) => onRowReorderEvent(e)}
            paginator
            rows={5}
            rowsPerPageOptions={[5, 10, 25, 50]}
          >
            <Column rowReorder style={{ width: "3em" }} />
            <Column sortable field="name" header="Etkinlik Adı" />
            <Column field="price" header="Etkinlik Fiyatı" />
            <Column
              header="Durum"
              body={(row) => {
                return row ? (
                  <InputSwitch
                    checked={row.status === 1 ? true : false}
                    onChange={(e) => updateEventStatus(row, e.value)}
                  />
                ) : null;
              }}
            />
            <Column body={actionTemplateEvent} style={{ width: "3em" }} />
          </DataTable>
        </div>
      </div>
      <Dialog
        header={
          selectedEvent && selectedEvent.id
            ? "Etkinlik Güncelleme"
            : "Etkinlik Ekleme"
        }
        visible={visibleEventDialog}
        className="w-full lg:w-8"
        onHide={() => {
          setSelectedEvent({
            name: "",
            description: "",
            image: "",
            price: "",
            categoryId: "",
          });
          setVisibleEventDialog(false);
        }}
      >
        <div className="p-5 border-round flex-auto">
          <div className="flex gap-5 flex-column-reverse md:flex-row">
            <div className="flex-auto p-fluid">
              <div className="mb-4">
                <label className="block font-medium text-900 mb-3">
                  Etkinlik Görseli
                </label>
                <Image
                  preview
                  width="200"
                  src={
                    selectedEvent && selectedEvent.image
                      ? selectedEvent.image
                      : null
                  }
                />
                <FileUpload
                  mode="basic"
                  accept="image/*"
                  customUpload
                  chooseLabel="Resim Seç"
                  onSelect={customBase64UploaderForEvent}
                  className={eventFormErrors.image ? "p-info" : ""}
                />
              </div>

              <div className="mb-4">
                <label className="block font-medium text-900 mb-2">
                  Etkinlik Adı
                </label>
                <InputText
                  value={
                    selectedEvent && selectedEvent.name
                      ? selectedEvent.name
                      : ""
                  }
                  onChange={(e) => {
                    setSelectedEvent({
                      ...selectedEvent,
                      name: e.target.value,
                    });
                  }}
                  type="text"
                  className={eventFormErrors.name ? "p-info" : ""}
                />
                {eventFormErrors.name && (
                  <small className="p-info">{eventFormErrors.name}</small>
                )}
              </div>

              <div className="mb-4">
                <label className="block font-medium text-900 mb-2">
                  Etkinlik Lokasyonu
                </label>
                <InputText
                  value={
                    selectedEvent && selectedEvent.location
                      ? selectedEvent.location
                      : ""
                  }
                  onChange={(e) => {
                    setSelectedEvent({
                      ...selectedEvent,
                      location: e.target.value,
                    });
                  }}
                  type="text"
                  className={eventFormErrors.location ? "p-info" : ""}
                />
                {eventFormErrors.location && (
                  <small className="p-info">{eventFormErrors.location}</small>
                )}
              </div>

              <div className="mb-4">
                <label className="block font-medium text-900 mb-2">
                  Etkinlik Şehiri
                </label>
                <Dropdown
                  value={
                    selectedEvent && selectedEvent.country
                      ? selectedEvent.country
                      : null
                  }
                  onChange={(e) =>
                    setSelectedEvent({
                      ...selectedEvent,
                      country: e.value,
                    })
                  }
                  options={countries}
                  optionLabel="name"
                  optionValue="id"
                  placeholder="Kategori Seç"
                  className={`w-full  ${
                    eventFormErrors.country ? "p-info" : ""
                  }`}
                />
                {eventFormErrors.country && (
                  <small className="p-info">{eventFormErrors.country}</small>
                )}
              </div>

              <div className="mb-4">
                <label className="block font-medium text-900 mb-2">
                  Etkinlik Açıklaması
                </label>
                <Editor
                  className={eventFormErrors.description ? "p-info" : ""}
                  value={
                    selectedEvent && selectedEvent.description
                      ? selectedEvent.description
                      : ""
                  }
                  onTextChange={(e) =>
                    setSelectedEvent({
                      ...selectedEvent,
                      description: e.htmlValue,
                    })
                  }
                  style={{ height: "320px" }}
                />
              </div>

              <div className="mb-4">
                <label className="block font-medium text-900 mb-2">
                  Etkinlik Bağlantı Adresi
                </label>
                <InputText
                  value={
                    selectedEvent && selectedEvent.link
                      ? selectedEvent.link
                      : ""
                  }
                  onChange={(e) => {
                    setSelectedEvent({
                      ...selectedEvent,
                      link: e.target.value,
                    });
                  }}
                  type="text"
                />
                {eventFormErrors.name && (
                  <small className="p-info">{eventFormErrors.name}</small>
                )}
              </div>

              <div className="mb-4">
                <label className="block font-medium text-900 mb-2">
                  Etkinlik Fiyatı
                </label>
                <InputNumber
                  value={
                    selectedEvent && selectedEvent.price
                      ? selectedEvent.price
                      : null
                  }
                  onValueChange={(e) => {
                    setSelectedEvent({
                      ...selectedEvent,
                      price: e.value,
                    });
                  }}
                  minFractionDigits={2}
                  className={eventFormErrors.price ? "p-info" : ""}
                />
                {eventFormErrors.price && (
                  <small className="p-info">{eventFormErrors.price}</small>
                )}
              </div>

              <div className="mb-4">
                <label className="block font-medium text-900 mb-2">
                  Etkinlik Tarihi
                </label>
                <Calendar
                  dateFormat="dd/mm/yy"
                  value={
                    selectedEvent && selectedEvent.date
                      ? new Date(selectedEvent.date)
                      : ""
                  }
                  onChange={(e) => {
                    setSelectedEvent({
                      ...selectedEvent,
                      date: e.value,
                    });
                  }}
                  locale="tr"
                  showTime
                  hourFormat="24"
                />
                {eventFormErrors.location && (
                  <small className="p-info">{eventFormErrors.date}</small>
                )}
              </div>

              <div>
                <Button
                  label="Kaydet"
                  onClick={() => submitEvent()}
                  className="p-ripple w-auto"
                ></Button>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
      <Dialog
        header="Silme İşlemi Onayı"
        visible={visibleConfirmDeleteEventDialog}
        className="w-full lg:w-3"
        footer={
          <div>
            <Button
              label="Hayır"
              icon="pi pi-times"
              onClick={() => setVisibleConfirmDeleteEventDialog(false)}
              className="p-button-text"
            />
            <Button
              label="Evet"
              icon="pi pi-check"
              onClick={confirmDeleteEvent}
              autoFocus
            />
          </div>
        }
        onHide={() => {
          setVisibleConfirmDeleteEventDialog(false);
        }}
      >
        <p>Bu etkinliği silmek istediğinizden emin misiniz?</p>
      </Dialog>
    </Fragment>
  );
};

export default Events;
